const selectAllFarActivities = () => $('.far-select-all').on('click', function() {
  var isChecked = $(this).prop('checked')
  $('.far-activities :checkbox').prop('checked', isChecked);
  $('.activity-checkboxes :checkbox').prop('checked', isChecked);
});

const checkForFeedbackInput = () => $('#faculty_activity_report_review_comments').on('keyup', function(){
  if ($(this).val() !== '') {
    $('#buttons input').attr('disabled', false)
  } else {
    $('#buttons input').attr('disabled', true)
  }
})

document.addEventListener('turbolinks:load', function() {
  selectAllFarActivities();
  checkForFeedbackInput();
});
