const filterCourseOfferingResults = () => $('#instructor_reports_filters').on('change', function(e) {
  filterResults(e);
});

const clearfilters = () => $('.clear-filters').on('click', function(e){
  $('#isr-course-feedback-event-select').val('').trigger('chosen:updated');
  $('#isr-term-select').val('').trigger('chosen:updated');
  $('#isr-org-select').val('').trigger('chosen:updated');
  $('#isr-faculty-select').val('').trigger('chosen:updated');
  filterResults(e);
});

const filterResults = function(e) {
  e.preventDefault();
  const courseFeedbackEventId = $('#isr-course-feedback-event-select').val();
  const orgId                 = $('#isr-org-select').val();
  const userId                = $('#isr-faculty-select').val();
  const termId                = $('#isr-term-select').val();
  $.ajax({
    type: 'GET',
    url: $('#instructor_reports_filters').attr('action'),
    dataType: 'script',
    data: { organization_id: orgId,
            course_feedback_event_id: courseFeedbackEventId,
            user_id: userId,
            term_id: termId },
  });
};

const initChosen = function() {
  $('#isr-faculty-select').chosen({ width: '100%'});
  $('#isr-faculty-select').attr('aria-label', 'Filter by Faculty Member');
  $('#isr-term-select').chosen({ width: '100%'});
  $('#isr-term-select').attr('aria-label', 'Filter by Term');
  $('#isr-org-select').chosen({ width: '100%'});
  $('#isr-org-select').attr('aria-label', 'Filter by Academic Unit');
  $('#isr-course-feedback-event-select').chosen({ width: '100%'});
  $('#isr-course-feedback-event-select').attr('aria-label', 'Filter by Course');
};

$(document).on('turbolinks:load', function() {
  filterCourseOfferingResults();
  clearfilters();
  initChosen();
});

$(document).on('turbolinks:before-cache', function() {
  $('#isr-org-select').chosen('destroy');
  $('#isr-course-feedback-event-select').chosen('destroy');
  $('#isr-term-select').chosen('destroy');
  $('#isr-faculty-select').chosen('destroy')
});

