const farSortableSections = function() {
  $('.faculty_activity_reporting_templates__structures .sortable').sortable({update(e, ui) {
    $.ajax({
      type: 'GET',
      url: $(this).data('url'),
      dataType: 'html',
      data: $(this).sortable('serialize')
    });
  }
  });
};

const farUpdateStyleAndFormat = function() {
  $('.faculty-activity-reporting-body').css('font-family', $('#faculty_activity_reporting_template_font_style_id option:selected').text());
  $('.document-section').css('font-size', $('#section_font_size').val() + "px");
  $('#faculty_activity_reporting_template_section_font_size').val($('#section_font_size').val());
  $('.document-subsection').css('font-size', $('#subsection_font_size').val() + "px");
  $('#faculty_activity_reporting_template_subsection_font_size').val($('#subsection_font_size').val());
  $('.document-activity').css('font-size', $('#faculty_activity_reporting_template_activity_font_size').val() + "px");
  $('.document-activity').css('line-height', $('#faculty_activity_reporting_template_activity_line_height').val());
};

const farSetupStyleAndFormat = function() {
  $('.style-and-format :input').on('input', () => farUpdateStyleAndFormat());
  farUpdateStyleAndFormat();
};

const farLoadStyles = () => $('#FacultyActivityReportingTemplate .btn.style').on('click', function(event) {
  event.preventDefault();
  const $button = $(this);
  const header = $(this).data('header');
  const style = $(this).data('style');

  if ($('#faculty_activity_reporting_template_' + header + '_' + style).val() === 'true') {
    $('.document-' + header).removeClass(style);
    $('#faculty_activity_reporting_template_' + header + '_' + style).val('false');
    $button.removeClass('active-option');
  } else {
    $('.document-' + header).addClass(style);
    $('#faculty_activity_reporting_template_' + header + '_' + style).val('true');
    $button.addClass('active-option');
  }
});

const farActivityDateOptions = function() {
  if ($('#faculty_activity_reporting_template_activity_date_option').val() === 'Date Range') {
    $('#activity_date_range_options').attr('hidden', false);
  }
  $('#faculty_activity_reporting_template_activity_date_option').on('change', function() {
    if ($(this).val() !== 'Date Range') {
      $('#activity_date_range_options').attr('hidden', true);
    } else {
      $('#activity_date_range_options').attr('hidden', false);
    }
  });
};

const farToggleOrganizationalUnits = () => $('#faculty_activity_reporting_template_personal').on('change', e => $('#organizations_section').toggle('hidden'));

const farProgressIndicator = () => $('.faculty-activity-reporting-step-btn').click(function(e) {
  e.preventDefault();
  const step = $(this).data('step');

  const no_submit_form = $('.faculty-activity-reporting-template-no-form-submit:first');
  if (no_submit_form.length > 0) {
    const href = $('.faculty-activity-reporting-template-no-form-submit:first').attr('href');
    $('.faculty-activity-reporting-template-no-form-submit:first').attr('href', href + step);
    $('.faculty-activity-reporting-template-no-form-submit:first')[0].click();
  } else {
    $('.faculty-activity-reporting-template-form #faculty_activity_reporting_template_next_action').val(step);
    $('.faculty-activity-reporting-template-form').submit();
  }
});

const clearFlashMessage = () => $('#createFarQuestions').on('shown.bs.modal', () => $('#createFarQuestions .flash-container').empty());

const initOrgsChosen = function() {
  $('#faculty_activity_reporting_template_organizations').chosen({ width: '100%', inherit_select_classes: true });
  $('#faculty_activity_reporting_template_organizations_chosen').attr('aria-label', 'Select Organizations');
};

$(document).on('turbolinks:load', function() {
  farSortableSections();
  farActivityDateOptions();
  farLoadStyles();
  farToggleOrganizationalUnits();
  farProgressIndicator();
  farSetupStyleAndFormat();
  clearFlashMessage();
  initOrgsChosen();
});

$(document).on('turbolinks:before-cache', () => $('#faculty_activity_reporting_template_organizations').chosen('destroy'));
