const initNewKaseChosen = () => $('#new_kase_modal').on('shown.bs.modal', function() {
  $('#kase_user_id, #kase_template_id').chosen({width: '100%'});
  $('.chosen-search-input').attr('aria-label', 'candidate');
  watchForCreateFromTemplateChange();
});

const initNewKaseDocumentChosen = () => $('#upload-case-document').on('show.bs.modal', function() {
  $('#kase_document_exclude_from_steps').chosen({width: '100%'});
  $('.chosen-search-input').attr('aria-label', 'documents to exclude');
});

const initBlacklistChosen = () => $('#new_kase_modal, #editKase').on('shown.bs.modal', () => $('#blacklist, #users_to_notify').chosen({
  hide_results_on_select: true,
  width: '100%'
}));

const refreshKaseFileFunctions = function() {
  $('#fileInput').on('change', function(){
    const chosenFilename = $(this).val();
    $('.custom-file-label').html(chosenFilename)
  })
};

var watchForCreateFromTemplateChange = function() {
  if ($('#kase_template_id')) {
    $('#kase_template_id').on('change', function() {
      $('#new_case_type').toggle(!$(this).val());
    });
  }
};

const sortableSteps = function() {
  $('#step_list_with_sort').sortable({update(e, ui) {
    $.ajax({
      type: 'PATCH',
      url: $(this).data('url'),
      data: $(this).sortable('serialize')
    });
  }
  });
};

const kaseNewDocumentUploadModalOnDocumentTypeChange = function() {
  $('#kase_document_document_type_id').on('change', function() {
    const docTypeId = $(this).val();
    const kaseId = $('#kase_document_kase_id').val();
    if (docTypeId) {
      $.ajax({
        type: 'GET',
        url: $(this).data('url'),
        data: { document_type_id: docTypeId, kase_id: kaseId
      },
        dataType: 'html',
        success(html) {
          $('#document--new-doc-container').html(html);
          $('#submit_btn').attr('disabled', false);
          refreshKaseFileFunctions();
          watchForTabChangeAndClearInputs();
        }
      });
    } else {
      $('#document--new-doc-container').html('');
      $('#submit_btn').attr('disabled', 'disabled');
    }
  });
};

var watchForTabChangeAndClearInputs = function() {
  $('#uploadFileOrUrl .nav-item').on('click', function(e) {
    const newTab = $(e.target).attr('href');
    if (newTab === '#attachUrl') {
      $('#fileInput').val(null);
      $('.custom-file-label').html('Upload File');
    } else if (newTab === '#uploadFile') {
      $('#url_').val('');
    }
  });
};

const evaluatorKaseInstructionsCollapse = function() {
  const $collapseLink = $('#collapse_instructions');
  $collapseLink.on('click', function() {
    const show_text = $collapseLink.data('showText');
    const hide_text = $collapseLink.data('hideText');
    if ($collapseLink.html() === show_text) {
      $collapseLink.html(hide_text);
    } else {
      $collapseLink.html(show_text);
    }
  });
};

const evaluatorKaseMaterialCollapse = function() {
  const $collapseLink = $('#collapse_case_materials');
  $collapseLink.on('click', function() {
    const show_text = $collapseLink.data('showText');
    const hide_text = $collapseLink.data('hideText');
    if ($collapseLink.html() === show_text) {
      $collapseLink.html(hide_text);
    } else {
      $collapseLink.html(show_text);
    }
  });
};

const toggleNextStep = () => $('#step-feedback-text-area').keyup(function() {
  if ($('#step-feedback-text-area').val().length > 0) {
    $('#next-step, #close-case').prop('disabled', false);
  } else {
    $('#next-step, #close-case').prop('disabled', true);
  }
});

const updateNextStep = () =>
  $('#next-step').on('click', function() {
    const url = $('#next-step-form').attr('action')
    const feedback = $('#step-feedback-text-area').val();
    $.ajax({
      type: 'POST',
      url: url,
      data: $('#step-feedback-form').serialize(),
      dataType: 'script',
      success: function() {
        return true
      }
    });
  });

const instructionsEnableTextArea = function() {
  $('.edit-instructions-btn').on('click', function(e) {
    e.preventDefault();
    $(this).hide();
    $('#edit-form-buttons').show();
    document.getElementsByClassName('case_instructions')[0].disabled = false;
    $('.hidden-instructions-form').each(function() { this.style.display = 'block'; });
    $('.trix-content').each(function() { this.style.display = 'none'; });
  });
  $('.instructions-form-cancel').on('click', function(e) {
    e.preventDefault();
    $('.edit-instructions-btn').show();
    $('#edit-form-buttons').hide();
    document.getElementsByClassName('case_instructions')[0].disabled = true;
    $('.hidden-instructions-form').each(function() { this.style.display = 'none'; });
    $('.trix-content').each(function() { this.style.display = 'block'; });
  });
};

const kaseSetUpDocumentActions = function() {
  $(document).on('click', '[data-submit-document-form]', function(e) {
    e.preventDefault();
    const id = $(this).data('submit-document-form');
    const form = document.getElementById(id);
    form.requestSubmit()
  });
};

const kaseFolderExplorerCollapseToggle = function() {
  const $collapseFEIcon = $('#folders-explorer-collapse i');
  const $folderExplorer = $('#folders-explorer');
  $folderExplorer.on('hide.bs.collapse', function(e) {
    if (e.target.id === 'folders-explorer') {
      $collapseFEIcon.removeClass('fa-chevron-down').addClass('fa-chevron-right');
    }
  });
  $folderExplorer.on('show.bs.collapse', function(e) {
    if (e.target.id === 'folders-explorer') {
      $collapseFEIcon.removeClass('fa-chevron-right').addClass('fa-chevron-down');
    }
  });
};

const kaseTagsCloudCollapseToggle = function() {
  const $collapseTCIcon = $('#tags-cloud-collapse i');
  const $tagsCloud = $('#tags-cloud');
  $tagsCloud.on('hide.bs.collapse', function(e) {
    if (e.target.id === 'tags-cloud') {
      $collapseTCIcon.removeClass('fa-chevron-down').addClass('fa-chevron-right');
    }
  });
  $tagsCloud.on('show.bs.collapse', function(e) {
    if (e.target.id === 'tags-cloud') {
      $collapseTCIcon.removeClass('fa-chevron-right').addClass('fa-chevron-down');
    }
  });
};

const kaseFilters = () => $('#kase-search-form').on('submit', function() {
  filterByKaseType();
  filterByKaseState();
});

var filterByKaseType = function() {
  var ids = [];

  $('.kase_type_filter input:checked').each(function() {
    ids.push(this.name);
  });

  $('#filters_kase_types').val(ids);
};

var filterByKaseState = function() {
  var ids = [];

  $('.kase_state_filter input:checked').each(function() {
    ids.push(this.name);
  });

  $('#filters_kase_states').val(ids);
};

const kaseSelectAllFunctions = function() {
  filterBySelectAll('.kase_type_filter');
  filterBySelectAll('.kase_state_filter');
};

var filterBySelectAll = filter_class => $(filter_class + ' #select_all').on('click', function() {
  if ($(filter_class + ' #select_all').prop('checked')) {
    $(filter_class + ' :checkbox').prop('checked', true);
  } else {
    $(filter_class + ' :checkbox').prop('checked', false);
  }
});

const initEvalKaseDocumentChosen = () => $('#upload-evaluator-document').on('show.bs.modal', function() {
  $('#kase_document_exclude_from_steps').chosen({width: '100%'});
  $('.chosen-search-input').attr('aria-label', 'documents to exclude');
});

const kaseEvalDocumentUploadModalOnDocumentTypeChange = function() {
  $('.category-select').on('change', function() {
    const docTypeId = $(this).val();
    const kaseId = $('#kase_document_kase_id').val();
    if (docTypeId) {
      $.ajax({
        type: 'GET',
        url: $(this).data('url'),
        data: { document_type_id: docTypeId, kase_id: kaseId
      },
        dataType: 'html',
        success(html) {
          $('#document--new-doc-container').html(html);
          $('#submit_btn').attr('disabled', false);
          refreshKaseFileFunctions();
          watchForTabChangeAndClearInputs();
        }
      });
    } else {
      $('#document--new-doc-container').html('');
      $('#submit_btn').attr('disabled', 'disabled');
    }
  });
};

const refreshEvaluatorFileFunctions = function() {
  $('#fileInput').on('change', function(){
    const chosenFilename = $(this).val();
    $('.custom-file-label').html(chosenFilename)
  })
};

document.addEventListener('turbolinks:load', function() {
  initNewKaseChosen();
  initNewKaseDocumentChosen();
  initBlacklistChosen();
  sortableSteps();
  kaseNewDocumentUploadModalOnDocumentTypeChange();
  kaseSetUpDocumentActions();
  kaseFolderExplorerCollapseToggle();
  kaseTagsCloudCollapseToggle();
  evaluatorKaseInstructionsCollapse();
  toggleNextStep();
  instructionsEnableTextArea();
  evaluatorKaseMaterialCollapse();
  updateNextStep();
  kaseFilters();
  kaseSelectAllFunctions();
  initEvalKaseDocumentChosen();
  kaseEvalDocumentUploadModalOnDocumentTypeChange();
  refreshEvaluatorFileFunctions();
});

$(document).on('turbolinks:before-cache', () => $('#kase_user_id, #kase_template_id, #blacklist, #users_to_notify, #kase_document_exclude_from_steps').chosen('destroy'));

$(document).on('change', '#kase_user_id', function(evt, params) {
  if (params.selected !== undefined) {
    $('#blacklist option[value="' + params.selected + '"]').remove();
    $('#blacklist').trigger('chosen:updated');
  }
});
