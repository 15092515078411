const categoryCollapse = () => $('.collapse-toggle').on('click', function() {
  const $collapseSection = $($(this).data('target'));

  if ($collapseSection.hasClass('collapsing')) {
    return false;
  }

  const section = $(this).data('sectionId');
  const $icon   = $('#section_collapse_category_' + section).find('i');
  const path    = $(this).data('path');

  if (section === 'contact_info') {
    $('#edit-contact-info').toggleClass('hidden');
  } else {
    const $addBtn = $(this).closest('div').find('.add-activity');
    $addBtn.toggleClass('hidden');
  }

  $icon.toggleClass('fa-chevron-down');
  $icon.toggleClass('fa-chevron-right');
  const isCollapsed = $icon.hasClass('fa-chevron-right');
  updateCollapsedCategoryUserSetting(path, section, isCollapsed);
});

var updateCollapsedCategoryUserSetting = (path, section, isCollapsed) => $.ajax({
  type: 'PUT',
  url: path,
  dataType: 'html',
  data: { section, collapsed: isCollapsed }});

const addTransitionComment = () => $('.add-transition-comment').on('click', function() {
  const $state_icon = $(this).find('span');
  const state       = $state_icon.data('state');
  const title       = $state_icon.data('title');
  const use_confirm = $state_icon.data('useConfirmDialog');
  $('#comment_activity_state').val(state);
  $('#transitionCommentLabel').html(title);
  $('#transitionComment').modal({show: true, backdrop: 'static'});
});

const selectInitialState = () => $('.initial-state-selector').on('click', function() {
  const state = $(this).data('state');
  $('#activity_state').val(state);
  $('.selected-state').removeClass('selected-state');
  $(this).addClass('selected-state');
});

const filterDownloadAsCv = function() {
  // Filter on typing
  $('.download-as-cv-dropdown input[name=search]').keyup(function() {
    const search_text = $(this).val().toLowerCase();
    $('.download-as-cv-item').filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(search_text) > -1);
    });
  });
  // Focus on search filter when drop down is shown
  $('.download-as-cv-dropdown').on('shown.bs.dropdown', function() {
    $(this).find('input').focus();
  });
};

const activityFilters = () => $('#activity-search-form').on('submit', function() {
  filterByOrg();
  filterByForm();
  filterByFacultyType();
});

var filterByOrg = function() {
  var ids = [];

  $('.org_filter input:checked').each(function() {
    ids.push(this.value);
  });

  $('#filters_orgs').val(ids);
};

var filterByForm = function() {
  var ids = [];

  $('.activity_form_filter input:checked').each(function() {
    ids.push(this.value);
  });

  $('#filters_activity_forms').val(ids);
};

var filterByFacultyType = function() {
  var ids = [];

  $('.faculty_type_filter input:checked').each(function() {
    ids.push(this.value);
  });

  $('#filters_faculty_types').val(ids);
};

const selectAllFunctions = function() {
  filterBySelectAll('.activity_form_filter');
  filterBySelectAll('.faculty_type_filter');
  filterBySelectAll('.org_filter');
};

var filterBySelectAll = filter_class => $(filter_class + ' #select_all').on('click', function() {
  if ($(filter_class + ' #select_all').prop('checked')) {
    $(filter_class + ' :checkbox').prop('checked', true);
  } else {
    $(filter_class + ' :checkbox').prop('checked', false);
  }
});

const exportAsCSV = () => $('.export-as-csv').on('click', function(e){
  e.preventDefault();

  const params = {
    search: $('.search-input').val(),
    filters: {
      orgs:           $('.org_filter input:checked').map(function() { return this.value; }).get().toString(),
      activity_forms: $('.activity_form_filter input:checked').map(function() { return this.value; }).get().toString(),
      faculty_types:  $('.faculty_type_filter input:checked').map(function() { return this.value; }).get().toString(),
      start_date:     $('#filters_start_date').val(),
      end_date:       $('#filters_end_date').val(),
      export_all:     $(this).data('exportAll')
    }
  };

  let url = $(this).data('url');
  url += '?' + $.param(params);
  window.open(url, '_blank');
});

const initChosen = function() {
  // activities new / edit page
  $('#association-value-select').chosen();
  $('#association_value_select_chosen .chosen-search-input').val('Select Collaborators');
  $('#association_value_select_chosen .chosen-search-input').attr('aria-label', 'Select Collaborators');
  // form show page
  $('#activity_forms_field_type_association_value').chosen();
  $('#activity_forms_field_type_association_value_chosen .chosen-search-input').attr('aria-label', 'Select Collaborators');
};

const initActivityFilterChosen = function() {
  $('#timeline_selection').chosen({ disable_search: true });
  $('#timeline_selection_chosen .chosen-search-input').attr('aria-label', 'select filter');
  $('#timeline_selection_chosen').attr('aria-label', 'timeline filter dropdown');
};

const removeDocumentFromActivity = () => $(document).on('click', '.remove-document', function(e){
  e.preventDefault();
  const $card = $(this).parents('.card');
  $card.remove();
});

const addPeopleList = function() {
  $('.add-people-list').chosen({
    placeholder_text_multiple: ' ',
    allow_single_deselect: true,
    hide_results_on_select: true,
    width: '100%'
  });
  $('#add_people_list_chosen .chosen-drop').hide();
  $('#add_people_list_chosen .chosen-search-input').attr('aria-label', 'Add People');

  return $('#add_people_list_chosen .chosen-search-input').keyup(function(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      const newValue = $(this).val();
      if (newValue) {
        const $selectElement = $('.add-people-list');
        const existingOption = $selectElement.find("option").filter( function() { return $(this).html() === newValue; }).length;
        if (!existingOption) {
          const option = $('<option>', { value: newValue, text: newValue }).prop("selected", true);
          $selectElement.append(option);
          $selectElement.trigger('chosen:updated').trigger('chosen:close');
        }
        e.target.focus();
      }
      return false;
    }
  });
};

const formatMyActivityFilterDates = () => $('.filter-date').datepicker({
  format: "yyyy/mm/dd",
  changeMonth: true,
  changeYear: true,
  showOtherMonths: true,
  selectOtherMonths: true,
  yearRange: '-100:+50'
});

const gridIdModal = () => $('#grid-id-modal').on('focus', function() {
  $('#grid-country-select').val('').trigger('chosen:updated');
  resetGridFilters();
  $('#selectGridIdModal').modal({show: true, backdrop: 'static'});
  $('#grid-country-select').chosen({width: '100%'});
  $('#grid-country-select_chosen .chosen-search-input').attr('aria-label', 'Select Country');
  $('#save_grid_id').hide();
});

const gridCountrySelect = function() {
  $('#grid-country-select').on('change', function() {
    const country = $(this).val();
    const url = $(this).data('url');
    updateGridFilters(null, country, url);
  });
};

const resetGridFilters = function() {
  $('#grid-state-filter').empty();
  $('#grid-institution-filter').empty();
}

const updateGridFilters = (state, country, url) => $.ajax({
  type: 'GET',
  url,
  dataType: 'html',
  data: { country: country, state: state },
  success(html) {
    html = JSON.parse(html);
    resetGridFilters();
    $('#grid-state-filter').append(html.states);
    $('#grid-state-select').chosen({ width: '100%' });
    $('#grid-state-select_chosen .chosen-search-input').attr('aria-label', 'Select State');
    if (html.institutions) {
      $('#grid-institution-filter').append(html.institutions);
      $('#grid-institution-select').chosen({ width: '100%' });
      $('#grid-institution-select_chosen .chosen-search-input').attr('aria-label', 'Select Institution');
      $('#grid-state-change').chosen({ width: '100%' });
      $('#grid-state-change_chosen .chosen-search-input').attr('aria-label', 'Select State');
      $('#save_grid_id').show();
      saveInstitution();
    }
    gridStateChange();
  }
});

const saveInstitution = function() {
  $('#save_grid_id').on('click', function(e) {
    e.preventDefault();
    const institution = $('#grid-institution-select').val();
    $('#selectGridIdModal').modal('hide');
    $('#grid-id-modal').val(institution);
  });
};

const gridStateChange = function() {
  $('#grid-state-change, #grid-state-select').on('change', function() {
    const state = $(this).val();
    const country = $('#grid-country-select').val();
    const url = $(this).data('url');
    updateGridFilters(state, country, url);
  });
};

const ongoingCheckChange = () => {
  $('.ongoing-check-box').on('click', function() {
    const datepicker =  $('#date_input_' + $(this).data('id'));
    datepicker.attr('disabled', $(this).prop('checked'));
    datepicker.val('');
  });
};

ongoingCheckOnLoad = () => {
  [...$('.ongoing-check-box')].forEach(checkbox => {
    $('#date_input_' + checkbox.dataset.id).attr('disabled', $(checkbox).prop('checked'));
  });
};

const initChosenBulk = function() {
  $('#organization_id').chosen({width: '100%', inherit_select_classes: true});
};

const saveCollaboratorOrder = () => $('#create-activity').on('submit', function() {
  var collaborators = $('.association-value-select')
  let parent = collaborators[0].parentNode;
  const arr = parent.getElementsByClassName('search-choice-close');
  var indexes = Array.prototype.slice.call( arr );
  const arr2 = collaborators[0].getElementsByTagName('option');
  var options = Array.prototype.slice.call( arr2 );
  var userids = indexes.map((selected) => {
    let index = selected.getAttribute('data-option-array-index');
    return options[index].value
  });
  $('.association-id-order').val(userids)
})

const reselectCollaborators = () => {
  if ($(document).hasClass('activities-edit') && $('.association-id-order').val() != null) {
    // get currently selected values (userIds) in the correct order
    const selected = $('.association-id-order').val().split(' ');
    let parent = $('.association-value-select')[0].parentNode;
    // get div for nodes to be reordered
    var choices = parent.querySelector('ul');
    // userid values in order of the dropdown
    const arr = $('.association-value-select')[0].getElementsByTagName('option');
    var options = Array.prototype.slice.call( arr );
    var userIds = options.map(option => option.value);

    // Iterate through selected array.  For each userId, use the index from userIds
    // to get the index of the correct node in the choices. Reinsert node into the correct position.
    selected.forEach((userId, i) => {
      var selectedOptions = choices.getElementsByClassName('search-choice');
      var allSelects = parent.getElementsByClassName('search-choice-close');
      var allSelects = Array.prototype.slice.call( allSelects );
      const indexes = allSelects.map(select => select.getAttribute('data-option-array-index'));
      var index = indexes.indexOf(userIds.indexOf(userId).toString());
      var node = selectedOptions.item(index);
      var nodeafter = choices.children[i].nextSibling;
      choices.insertBefore(node, nodeafter);
    });
  }
}

const togglePublished = () => $('.publish-toggle').on('click', function() {
  $.ajax({
    type: 'GET',
    url: $(this).data('url'),
    dataType: 'html',
    data: { activity_id: $(this).data('activity_id') },
  })
  $(this).find('i').toggleClass('fa-toggle-on fa-toggle-off');
  $(this).find('i').toggleClass('btn-outline-danger btn-outline-primary');
});

const toggleReadOnly = () => $('.read-only-toggle').on('click', function() {
  $.ajax({
    type: 'GET',
    url: $(this).data('url'),
    dataType: 'html',
    data: { activity_id: $(this).data('activity_id') },
  })
  $(this).find('i').toggleClass('fa-toggle-on fa-toggle-off');
  $(this).find('i').toggleClass('btn-outline-danger btn-outline-primary');
});

const setEndDateValue = () => $('#academic-date-select').on('change', function() {
  const i = $(this).find(":selected").index();
  $('#end-date-select').prop('selectedIndex', i);
});

$(document).on('turbolinks:load', function() {
  toggleReadOnly();
  togglePublished();
  categoryCollapse();
  addTransitionComment();
  selectInitialState();
  filterDownloadAsCv();
  activityFilters();
  selectAllFunctions();
  removeDocumentFromActivity();
  exportAsCSV();
  initChosen();
  initActivityFilterChosen();
  addPeopleList();
  formatMyActivityFilterDates();
  gridIdModal();
  gridCountrySelect();
  ongoingCheckChange();
  initChosenBulk();
  ongoingCheckOnLoad();
  saveCollaboratorOrder();
  reselectCollaborators();
  setEndDateValue();
});

$(document).on('turbolinks:before-cache', function() {
  $('#association-value-select').chosen('destroy');
  $('#activity_forms_field_type_association_value').chosen('destroy');
  $('#timeline_selection').chosen('destroy');
  $('.add-people-list').chosen('destroy');
  $('#activity_institution').chosen('destroy');
});
