const initImportsChosen = function() {
  $('#filter-import-row-years').chosen({
    width: '100%',
    placeholder_text_multiple: $('#filter-import-row-years').data('prompt')
  });
  $('#filter_import_row_years_chosen').attr('aria-label', 'filter by years')
};

const allFilters = () => {
  return {
    import_id: $('#import_id').val(),
    import_start_year: $('#filter-import-row-start').val(),
    import_end_year: $('#filter-import-row-end').val(),
    import_filter_years: $('#filter-import-row-years').val(),
    import_filter_author: $('#filter-import-row-author').val(),
    sort_type: $('.sort-import-dropdown').val(),
    current_page: $('.scroll-page').val(),
    select_all: $('.select-all-import-rows').prop('checked')
  }
}

const addImportFormBody = () => $('#import-type-selector').on('change', function(e) {
  const import_type = $(this).val();
  return $.ajax({
    type: 'GET',
    url: $(this).data('url'),
    dataType: 'script',
    data: { import_type: import_type },
  });
});

const sortImportRows = () => $('.sort-import-dropdown').on('change', function(e) {
  e.preventDefault();

  $.ajax({
    type: 'GET',
    url: $(this).data('url'),
    dataType: 'script',
    data: allFilters()
  });
})

const submitImportForm = () => $('.create-activities').on('click', function(e) {
  e.preventDefault();
  const importForm = $('#import-form');
  importForm.trigger('submit.rails');
})

const selectAllImportRows = () => $('.select-all-import-rows').on('click', function() {
  $('.import-rows :checkbox').prop('checked', $(this).prop('checked'));
  $('.select-all-field').val($(this).prop('checked'));
  const import_id = $('#import_id').val();
  $.ajax({
    type: 'PATCH',
    url: '/imports/' + import_id + '/select_import_rows',
    dataType: 'html',
    data: { selected: $(this).prop('checked') }
  });
});

const importFilterChange = () => $('#filter-import-row-author, #filter-import-row-years, #filter-import-row-start, #filter-import-row-end').on('change', function(){
  loadFilterResults();
});

const scrollListener = (element) => element.on('scroll', function(e) {
  var scrollDiv = element[0];
  var scrollHeight = scrollDiv.scrollHeight - scrollDiv.scrollTop - 1;
  var scrollBottom = scrollDiv.clientHeight;
  if(scrollHeight <= scrollBottom) {
    loadNextRows();
  }
});

const loadNextRows = () => {
  $.ajax({
    type: 'GET',
    url: $('#load-next-url').val(),
    dataType: 'script',
    data: allFilters()
  });
}

const loadFilterResults = function() {
  const import_id = $('#import_id').val();
  const filter_start_year = $('#filter-import-row-start').val();
  const filter_end_year = $('#filter-import-row-end').val();
  const filter_years = $('#filter-import-row-years').val();
  const filter_author = $('#filter-import-row-author').val();
  $.ajax({
    type: 'GET',
    url: "/imports/" + import_id + "/filter_import_rows",
    dataType: 'html',
    data: {
      import_id: import_id,
      import_start_year: filter_start_year,
      import_end_year: filter_end_year,
      import_filter_years: filter_years,
      import_filter_author: filter_author,
      sort_type: $('.sort-import-dropdown').val(),
      current_page: $('.scroll-page').val()
    },
    success(html) {
      $('#import-details').replaceWith(html);
      $('.import-actions').show();
      $('.dedupe-container').hide();
      $('.select-all-import-rows').prop("checked", false);
      sortImportRows();
      selectImportRow();
      selectAllImportRows();
      showDuplicate();
      scrollListener($('#import-rows'));
    }
  });
};

const showDuplicate = () => $('.duplicate-flag').on('click', function(e) {
  e.preventDefault();

  const selectedDuplicateId = $(this).data('duplicateId');

  $.ajax({
    type: 'GET',
    url: $(this).data('url'),
    dataType: 'html',
    data: allFilters(),
    success(html) { successCallback(html, selectedDuplicateId) }
  });

  function successCallback(html, duplicateId) {
    $('.dedupe-container').replaceWith(html);
    $('.import-details').hide();
    $('.import-actions').hide();
    toggleSelectedDupe(duplicateId);
    toggleDuplicateAction();
    cancelDuplicateAction();
    submitDuplicateAction();
  }
});

const toggleSelectedDupe = (duplicateId) => {
  const selectedDupe = $(`#duplicate-list-item-${duplicateId}`);
  const previous = $('#currently-selected-duplicate').val();
  if (previous != -1) {
    $(`#duplicate-list-item-${previous}`).removeClass('selected');
  }
  selectedDupe.addClass('selected');
  $('#currently-selected-duplicate').val(duplicateId);
}

const toggleDuplicateAction = () => $('.duplicate-actions').find('input[type="radio"]').on('click', function(e) {
  var selected = $(this)[0];

  $('.duplicate-actions').find('input[type="radio"]').toArray().forEach((action, i) => {
    if (action == selected) {
      $(action).prop('checked', true);
    } else {
      $(action).prop('checked', false);
    }
    $('.submit-action').removeClass('disabled');
  });
})

const cancelDuplicateAction = () => $('.submit-duplicate-actions .cancel-action').on('click', function(e) {
  e.preventDefault();
  const currentDupeId = $('#currently-selected-duplicate').val();
  const selectedDupe = $(`#duplicate-list-item-${currentDupeId}`);
  selectedDupe.removeClass('selected');

  $('.dedupe-container').hide();
  $('.import-details').show();
  $('.import-actions').show();
})

const findNextDuplicate = function(duplicateId, importRowId) {
  currentDuplicate = $(`#duplicate-list-item-${duplicateId}`);
  while(currentDuplicate.find('.duplicate-flag').data('importRowId') == importRowId) {
    next = currentDuplicate.next();
    currentDuplicate.remove();
    if (next[0].getAttribute('id') == 'currently-selected-duplicate') {
      if ($('.duplicate-list').children.length > 0) {
        next = $('.duplicate-list').children().first();
      }
    }
    currentDuplicate = next;
  }
  $('.duplicate-list').children().toArray().forEach((element, index) => {
    if ($(element).find('.duplicate-flag').data('importRowId') == importRowId) {
      $(element).remove();
    }
  })
  return currentDuplicate;
}

const submitDuplicateAction = () => $('.submit-duplicate-actions .submit-action').on('click',
  function(e) {
    e.preventDefault();
    const selectedAction = $('input[type="radio"]:checked', '.duplicate-actions');
    const actionUrl = selectedAction.data('url');
    const requestType = selectedAction.data('requestType');
    const duplicateId = $('#currently-selected-duplicate').val();
    const currentDuplicate = $(`#duplicate-list-item-${duplicateId}`);
    const importRowId = currentDuplicate.find('.duplicate-flag').data('importRowId');
    const nextDuplicate = findNextDuplicate(duplicateId, importRowId);
    const nextDuplicateId = nextDuplicate.find('.duplicate-flag').data('duplicateId');
    const rowId = $(this).closest('.dedupe-container').find('.duplicate-flag').data('rowId');

    $.ajax({
      type: requestType,
      url: actionUrl,
      dataType: 'html',
      data: { next_duplicate_id: nextDuplicateId },
      success(html) {
        if(parseInt(duplicateId) != nextDuplicateId) {
          successCallback(html, duplicateId, nextDuplicateId, rowId);
        } else {
          window.location.reload();
        }
      }
    });

    function successCallback(html, currentDuplicateId, nextDuplicateId, rowId) {
      $(`#duplicate-list-item-${currentDuplicateId}`).remove();
      toggleSelectedDupe(nextDuplicateId);
      $('#import-rows').find(`#import_row_${rowId}`).remove();
      $('.dedupe-container').replaceWith(html);
      toggleDuplicateAction();
      cancelDuplicateAction();
      submitDuplicateAction();
    }
});

const selectImportRow = () => $('.select-import-row').on('click', function() {
  const import_row_id = $(this).attr('value');
  const selected = $(this).prop('checked')
  $.ajax({
    type: 'PATCH',
    url: '/import_rows/' + import_row_id,
    dataType: 'html',
    data: { selected: selected }
  });
});

const newCriteriaDetail = () => $('.add-search-criteria').on('click', function(e) {
  e.preventDefault();

  $.ajax({
    type: 'GET',
    url: '/imports/import_search_criterium/new_criteria_detail',
    dataType: 'script'
  });
});

const removeCriteriaDetail = () => $('.remove-search-criteria').on('click', function(e) {
  e.preventDefault();

  $("#criteria_detail_" + $(this).data('id')).remove()
});

const replaceCriteriaDetail = () => $('.select-search-criteria').on('change', function(e) {
  return $.ajax({
    type: 'GET',
    url: '/imports/import_search_criterium/replace_criteria_detail',
    dataType: 'script',
    data: { criteria_id: $(this).val(), current_div: $(this).data('id') }
  });
});

const formatSearchCriteriaDates = () => $('.datepicker').datepicker({
  format: "yyyy-mm-dd",
  changeMonth: true,
  changeYear: true,
  showOtherMonths: true,
  selectOtherMonths: true,
  yearRange: '-100:+50'
});

const updateDropdownData = () => {
  $('.term-dropdown').chosen({width: '100%'});
  $('.term-dropdown').on('change', function() {
    $('#term_filter_value').val($(this).val());
    if ($(this).val() != '') {
      $('.import-form-submit').attr('disabled', false);
    } else {
      $('.import-form-submit').attr('disabled', true);
    };
  });
};

$(document).on('turbolinks:load', function() {
  updateDropdownData();
  initImportsChosen();
  sortImportRows();
  addImportFormBody();
  selectAllImportRows();
  importFilterChange();
  scrollListener($('#import-rows'));
  submitImportForm();
  showDuplicate();
  selectImportRow();
  newCriteriaDetail();
  removeCriteriaDetail();
  replaceCriteriaDetail();
  formatSearchCriteriaDates();
});

$(document).on('turbolinks:before-cache', () => $('#filter-import-row-years').chosen('destroy'));
window.addImportFormBody = addImportFormBody;
window.sortImportRows = sortImportRows;
window.selectImportRow = selectImportRow;
window.selectAllImportRows = selectAllImportRows;
window.scrollListener = scrollListener;
window.newCriteriaDetail = newCriteriaDetail;
window.removeCriteriaDetail = removeCriteriaDetail;
window.replaceCriteriaDetail = replaceCriteriaDetail;
window.formatSearchCriteriaDates = formatSearchCriteriaDates;
window.showDuplicate = showDuplicate;
window.toggleSelectedDupe = toggleSelectedDupe;
window.cancelDuplicateAction = cancelDuplicateAction;
window.submitDuplicateAction = submitDuplicateAction;
