const searchVideosByName = () => $('.help_videos-index .search-input').on('keyup', function(e) {
  e.preventDefault();
  if (e.key == 'Meta') return;

  const url = $(this).closest('form').attr('action')
  const search = $(this).val();

  $.ajax({
    type: 'GET',
    url: url,
    dataType: 'script',
    data: { query: search }
  });
});

const preventHelpFormSubmission = () => $('#help_video_search').on('submit', function(e){
  e.preventDefault();
  return false
});

$(document).on('turbolinks:load', function() {
  searchVideosByName();
  preventHelpFormSubmission();
});