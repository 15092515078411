function setUpDocumentActions() {
  $(document).on('click', '#save-document-changes', function(e) {
    e.preventDefault();

    var id   = $(this).data('submit-document-form')
    var form = document.getElementById(id);
    form.requestSubmit()
  });
}

function refreshKaseDocumentFileFunctions(){
  $('#fileInput').on('change', function(){
    const chosenFilename = $(this).val();
    $('.custom-file-label').html(chosenFilename)
  })
}

function updateNewDocumentUploadModalOnDocumentTypeChange() {
  $('#dossiers_document_document_type_id').on('change', function(){
    var docTypeId = $(this).val();
    var url = $(this).data('url');

    if (docTypeId) {
      $.ajax({
        type: 'GET',
        url:  url,
        data: { document_type_id: docTypeId },
        dataType: 'html',
        success: function(html){
          $('#document--new-doc-container').html(html);
          $('#submit_btn').attr('disabled', false);
          refreshKaseDocumentFileFunctions();
          watchForTabChangeAndClearInputs();
        }
      });
    } else {
      $('#document--new-doc-container').html('');
      $('#submit_btn').attr('disabled', 'disabled');
    }
  })
}

function watchForTabChangeAndClearInputs() {
  $('#uploadFileOrUrl .nav-item').on('click', function (e) {
    var newTab = $(e.target).attr("href");

    if (newTab === "#attachUrl") {
      $('#fileInput').val(null);
      $('.custom-file-label').html('Upload File');
    } else if (newTab === "#uploadFile") {
      $('#url_').val('');
    }
  });
}

function folderExplorerCollapseToggle(){
  $collapseFEIcon   = $('#folders-explorer-collapse i');
  $folderExplorer = $('#folders-explorer');

  $folderExplorer.on('hide.bs.collapse', function (e) {
    if (e.target.id === 'folders-explorer') {
      $collapseFEIcon.removeClass('fa-chevron-down').addClass('fa-chevron-right');
    }
  });

  $folderExplorer.on('show.bs.collapse', function (e) {
    if (e.target.id === 'folders-explorer') {
      $collapseFEIcon.removeClass('fa-chevron-right').addClass('fa-chevron-down');
    }
  });
}

function tagsCloudCollapseToggle(){
  $collapseTCIcon   = $('#tags-cloud-collapse i');
  $tagsCloud = $('#tags-cloud');

  $tagsCloud.on('hide.bs.collapse', function (e) {
    if (e.target.id === 'tags-cloud') {
      $collapseTCIcon.removeClass('fa-chevron-down').addClass('fa-chevron-right');
    }
  });

  $tagsCloud.on('show.bs.collapse', function (e) {
    if (e.target.id === 'tags-cloud') {
      $collapseTCIcon.removeClass('fa-chevron-right').addClass('fa-chevron-down');
    }
  });
}

const initChosen = function() {
  $("#documents_users_filter").chosen({ width: '100%', placeholder_text_multiple: 'Filter by User(s)' });
  $('#documents_users_filter .chosen-search-input').attr('aria-label', 'Filter Documents by Users');
};

const applyFiltersOnChange = () => $('.search__documents-index .filters :input').on('change', function() {
  $('#search-Documents').click();
});

const searchDocumentsOnKeyup = () => $('.search__documents-index .search-input').on('keyup', function(e) {
  setTimeout(function() { $('#search-Documents').click(); }, 1500);
});

document.addEventListener("turbolinks:load", function() {
  initChosen();
  applyFiltersOnChange();
  searchDocumentsOnKeyup();
  updateNewDocumentUploadModalOnDocumentTypeChange();
  setUpDocumentActions();
  folderExplorerCollapseToggle();
  tagsCloudCollapseToggle();
});
