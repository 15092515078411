const displayBiosketchFields = () => $('#biosketch-continue').on('click', function(e) {
  $('.biosketch-activity-fields').removeClass('hidden');
  $('.biosketch-personal-fields').addClass('hidden');
  $('#biosketch_builder_research_activity_ids, #biosketch_builder_other_research_activity_ids, #biosketch_builder_synergistic_activity_ids').chosen({width: '100%', max_selected_options: 5 });
  $('#edu-activities-select, #experience-activities-select, #awards-and-honors-select, #biosketch_builder_nih_research_activity_ids').chosen({width: '100%'});
  $('#biosketch_builder_research_activity_ids_chosen .chosen-search-input, #biosketch_builder_other_research_activity_ids_chosen .chosen-search-input, #biosketch_builder_synergistic_activity_ids_chosen .chosen-search-input, #edu_activities_select_chosen .chosen-search-input, #experience_activities_select_chosen .chosen-search-input, #awards_and_honors_select_chosen .chosen-search-input, #biosketch_builder_nih_research_activity_ids .chosen-search-input').attr('aria-label', 'Select Activity');
});

const nextBiosketchForm = () => $('#bio-types').on('change', function() {
  if ($('#bio-types').val() === "National Science Foundation (NSF)") {
    $('#biosketch-type').attr('action', '/nsf_builders/next');
  } else if ($('#bio-types').val() === "National Institute of Health (NIH)") {
    $('#biosketch-type').attr('action', '/nih_builders/next');
  }
  $('#biosketch-type').submit();
});

$(document).on('turbolinks:load', function() {
  nextBiosketchForm();
  displayBiosketchFields();
});
