function initLayoutCollapsable() {
  $('#layout-collapse').click(function () {
    const icon = $(this).find('i');
    if (icon.hasClass('fa-chevron-down')) {
      icon.addClass('fa-chevron-right').removeClass('fa-chevron-down')
    } else {
      icon.addClass('fa-chevron-down').removeClass('fa-chevron-right')
    }
  });
}

const addMacroToTextArea = function() {
  $('#email-center-middle').on('click', '.macro', function() {
    const value = '{{' + $(this).data('label') + '}}';
    document.querySelector("trix-editor").editor.insertHTML(value);
  });
};

$(document).on('turbolinks:load', function() {
  initLayoutCollapsable();
  addMacroToTextArea();
});
