const selectRole = () => $('.role-select').on('click', function(e) {
  e.preventDefault();
  if ($('.save-role-button').is(':visible')) {
    if (!($('.save-role-button').hasClass('disabled'))) {
      if (!confirm('Changes have not been saved, are you sure you wish to proceed?')) {
        return
      }
    }
  }
  $('.selected').removeClass('selected');
  $(this).addClass('selected');
  var url = $(this).attr('href');
  $.ajax({
    type: 'GET',
    url: url,
    dataType: 'html',
    data: {},
    success: function(html) {
      $('.permissions-list').html(html);
      checkForDataChange();
      submitRoleChanges();
      deleteRole();
      cloneRole();
      toggleCollapse();
      viewPermDescription();
    }
  })
});

const submitRoleChanges = () => $('.save-role-button').on('click', function(e) {
  e.preventDefault();
  $('#submit-form').click();
});

const checkForDataChange = () => $('.role-form input').on('change', function(e) {
  e.preventDefault();

  $('.save-role-button').removeClass('disabled');
});

const deleteRole = () => $('.delete-role-button').on('click', function() {
  const url = $('.role-form').attr('action');
  if (confirm('Are you sure you wish to delete this role? This action cannot be undone.')) {
    $.ajax({
      type: 'DELETE',
      url: url,
      dataType: 'script',
      data: { destroy_for_all_users: true }
    });
  }
  $('.bs-tooltip-top').remove();
})

const cloneRole = () => $('.clone-role-button').on('click', function() {
  const url = $(this).data('url');
  $.ajax({
    type: 'POST',
    url: url,
    dataType: 'script',
    data: { id: $(this).data('roleId')}
  })
  $('.bs-tooltip-top').remove()
})

const viewPermDescription = () => $('.permission-row').on('click', function () {
  $.ajax({
    type: 'GET',
    url: '/users/roles/display_permission_info',
    dataType: 'html',
    data: {perm_id: $(this).attr('data-id')},
    success: function(html) {
      $('.permissions-info').replaceWith(html);
    }
  })
})

const toggleCollapse = () => $('.toggle-child-permissions').on('click', function() {
  target = $(this).data('target');
  $(target).toggleClass('hidden');
})

$(document).on('turbolinks:load', function() {
  submitRoleChanges();
  selectRole();
  checkForDataChange();
  deleteRole();
  viewPermDescription();
});

window.selectRole = selectRole;
window.checkForDataChange = checkForDataChange;
window.cloneRole = cloneRole;
window.viewPermDescription = viewPermDescription;
