const toggleButton = function(e) {
  e.preventDefault();

  // Visual Changes
  $(this).find('i').toggleClass('fa-toggle-on fa-toggle-off');
  $(this).find('i').toggleClass('btn-outline-danger btn-outline-primary');
  $(this).next().toggleClass('d-none'); // Hide/Show Unsaved Toggle Message

  // Update value for form
  const target = $('#' + $(this).data('target'));
  const new_value = !(target.val() === "true");
  return target.val(new_value);
};

$(document).on('turbolinks:load', function() {
  $('.toggle-reminder-config').on('click', toggleButton);
});
