const initOrgTreeCollapsable = function() {
  $('#tree-source .tree-collapse-button').click(function () {
    const icon = $(this).find('i');
    if (icon.hasClass('fa-chevron-down')) {
      icon.addClass('fa-chevron-right').removeClass('fa-chevron-down')
    } else {
      icon.addClass('fa-chevron-down').removeClass('fa-chevron-right')
    }
  });
};

const createSubcategory = function() {
  $('.create-subcategory').on('click', function() {
    const url        = $(this).parent('div').data('url');
    const name       = $(this).parent('div').data('name');
    const label      = $(this).parent('div').data('label');
    $.ajax({
      type: 'GET',
      url,
      data: { name, label },
      dataType: 'html',
      success(html) {
        $('#createSubcategory .modal-body').html(html);
        $('#createSubcategory').modal({show: true, backdrop: 'static'});
        ensureOrgsSelected();
      },
      error(response) {
        if (response.status === 422) {
          $('#createSubcategory .modal-body').html(response.responseText);
          $('#createSubcategory').modal({show: true, backdrop: 'static'});
          ensureOrgsSelected();
        }
      }
    });
  });
};

const sortableSubCategories = function() {
  $('.subcategories .sortable#subcategories').sortable({update(e, ui) {
    let ids = $(this).sortable('toArray');
    ids = $.map(ids, id => id.replace(/subcategory_/,'').replace(/_row/,''));
    $.ajax({
      type: 'PUT',
      url: $(this).data('url'),
      dataType: 'html',
      data: { subcategory_ids: ids }});
  }
  });
};

const editCategory = function() {
  $('.edit-category').on('click', function() {
    const url = $(this).parent('div').data('url');
    const title = $(this).parent('div').data('title');
    $.ajax({
      type: 'GET',
      url,
      dataType: 'html',
      success(html) {
        $('#editCategory .modal-body').html(html);
        $('#editCategory .modal-title').html(title);
        $('#editCategory').modal({show: true, backdrop: 'static'});
        ensureOrgsSelected();
        initOrgTreeCollapsable();
      },
      error(response) {
        if (response.status === 422) {
          $('#editCategory .modal-body').html(response.responseText);
          $('#editCategory .modal-title').html(title);
          $('#editCategory').modal({show: true, backdrop: 'static'});
          ensureOrgsSelected();
          initOrgTreeCollapsable();
        }
      }
    });
  });
};

var ensureOrgsSelected = () => $('.category-form, .subcategory-form').on('submit', function(e) {
  const $orgForm = $(this).find('.organizations-form');
  if ($orgForm && ($orgForm.find('input:checkbox:checked').length === 0)) {
    $('.organizations-label span').attr('hidden', false);
    $orgForm.addClass('form-error');
    return false;
  }
});

const collapsableCategories = () => $('.toggle-subcategories').on('click', function() {
  if ($(this).find('i').hasClass('fa-chevron-down')) {
    $(this).find('i').removeClass('fa-chevron-down').addClass('fa-chevron-up');
  } else if ($(this).find('i').hasClass('fa-chevron-up')) {
    $(this).find('i').removeClass('fa-chevron-up').addClass('fa-chevron-down');
  }
});

const handleBadOrg = () => $('.bad-org').parents('.category-card').css('border-color', 'red');

const sortableCategories = function() {
  $('.categories .sortable#sortable_sections').sortable({update(e, ui) {
    let ids = $(this).sortable('toArray');
    ids = $.map(ids, id => id.replace(/category_/,'').replace(/_row/,''));
    $.ajax({
      type: 'PUT',
      url: $(this).data('url'),
      dataType: 'html',
      data: { category_ids: ids }});
  }
  });
};

const sortableActivityForms = function() {
  $('.activity-links .sortable#sortable_sections').sortable({update(e, ui) {
    let ids = $(this).sortable('toArray');
    ids = $.map(ids, id => id.replace(/activity_form_/,'').replace(/_row/,''));
    $.ajax({
      type: 'PUT',
      url: $(this).data('url'),
      dataType: 'html',
      data: { activity_forms_ids: ids }});
  }
  });
};

$(document).on('turbolinks:load', function() {
  createSubcategory();
  editCategory();
  ensureOrgsSelected();
  collapsableCategories();
  handleBadOrg();
  sortableCategories();
  sortableSubCategories();
  sortableActivityForms();
});
