const editCitation = function() {
  $('.edit-citation').on('click', function() {
    const url = $(this).parent('div').data('url');
    $.ajax({
      type: 'GET',
      url,
      dataType: 'html',
      success(html) {
        $('#editCitation .modal-body').html(html);
        $('#editCitation').modal({show: true, backdrop: 'static'});
      }
    });
  });
};

const closePopoverOnClick = () => $('.activity_forms-citations').on('click', function(e) {
  const notPopoverButton = $(e.target).parents('.citation-popover-btn').length === 0;
  const notPopover = $(e.target).parents('.popover').length === 0;

  if (notPopoverButton && notPopover) {
    $('.popover .popover-body').html('');
    $('.popover').popover('hide');
  }
});

$(document).on('turbolinks:load', function() {
  editCitation();
  closePopoverOnClick();
});
