const addMacroToTextArea = function() {
  $('.macro').on('click', function() {
    var span = document.createElement('span');
    span.style.fontFamily = 'Times New Roman, serif';
    const value = '{{' + $(this).data('label') + '}}';
    span.appendChild(document.createTextNode(value));
    $('#summernote').summernote('editor.insertNode', span);
  });
};

const previewHeading = function() {
  $('#preview-btn').on('click', function(event) {
    $.ajax({
      type: 'GET',
      url: $(this).data('url'),
      data: {
        activity_template_heading_preview: $('.note-editable').html(),
      },
      dataType: 'html',
      success(html) {
        $('#heading-preview').replaceWith(html);
      }
    });
  });
};

const uploadFileToS3 = function(file) {
  const activity_template_id = $('#activity_template_id').val();
  const data = new FormData();
  data.append("file", file);
  $.ajax({
    data,
    type: "POST",
    url: "/activity_templates/" + activity_template_id + "/image",
    cache: false,
    contentType: false,
    processData: false,
    beforeSend() {
      $('#image-spinner').show();
    },
    complete() {
      $('#image-spinner').hide();
    },
    success(url) {
    },
    error(XMLHttpRequest, textStatus, errorThrown) {
    }
  });
};

const deleteFileFromS3 = function(file) {
  const activity_template_id = $('#activity_template_id').val();
  $.ajax({
    data: { file },
    type: "DELETE",
    url: "/activity_templates/" + activity_template_id + "/image",
    cache: false,
    success(url) {
    }
  });
};

const updateImageDimentions = () => $('#submit-heading').on('click', function(e) {
  e.preventDefault();
  const logo = $('.note-editable img');
  if (logo.length > 0) {
    const activity_template_id = $('#activity_template_id').val();
    const width = logo[0].width.toString() + "px";
    const height = logo[0].height.toString() + "px";
    $.ajax({
      data: { width, height },
      dataType: 'html',
      type: "PUT",
      url: "/activity_templates/" + activity_template_id + "/image",
      cache: false,
      success(html) {
        $('#heading-form').submit();
      }
    });
  } else {
    $('#heading-form').submit();
  }
});

$(document).on('turbolinks:load', function() {
  addMacroToTextArea();
  previewHeading();
  updateImageDimentions();
  const $summernote = $('#summernote');
  $summernote.summernote({
    height: '300px',
    fontNames: ['Arial', 'Trebuchet', 'Georgia', 'Times New Roman', 'Verdana'],
    fontSizes: ['8', '9', '10', '11', '12', '14', '16', '18', '24', '36'],
    toolbar: [
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['fontsize', ['fontname', 'fontsize']],
      ['para', ['paragraph', 'hr']],
      ['height', ['height']],
      ['link', ['link', 'unlink']],
      ['misc', ['codeview', 'table', 'picture']],
    ],
    callbacks: {
      onImageUpload(file) {
        uploadFileToS3(file[0]);
      },

      onMediaDelete(file) {
        deleteFileFromS3(file[0].src);
      }
    }
  });
  $('.note-popover').hide();
});

$(document).on('turbolinks:before-cache', () => $('#summernote').summernote('destroy'));
