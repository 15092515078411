const searchFieldTypesByName = () => $('.activity_field_center_index .search-input').on('keyup', function(e) {
  if (e.key == 'Meta') return;

  loadSearchAndFilterResults();
});

const activityFieldFilterChange = () => $('#filter-activity-field, #filter-tag').on('change', function(){
  loadSearchAndFilterResults();
});

const loadSearchAndFilterResults = function() {
  const filter_types = $('#filter-activity-field').val();
  const filter_tags = $('#filter-tag').val();
  const search = $('.activity_field_center_index .search-input').val();

  $.ajax({
    type: 'GET',
    url: '/search/field_types/search_and_filter_field_types',
    dataType: 'html',
    data: {
      filter_types: filter_types,
      filter_tags: filter_tags,
      query: search
    },
    success(html) {
      $('#field-types').replaceWith(html);
      selectFieldType();
    }
  });
};

const selectFieldType = () => $(document).on('click', '.field-type-selection', function() {
  let currentSelection = $('.field-type-selection.selected');
  if (currentSelection) {
    currentSelection.removeClass('selected');
  }
  $(this).addClass('selected');
});

const removeSelectedWithCreateButton = () => $('#create-new-field-type').on('click', function() {
  let currentSelection = $('.field-type-selection.selected');
  if (currentSelection) {
    currentSelection.removeClass('selected');
  }
});

$(document).on('turbolinks:load', function() {
  searchFieldTypesByName();
  selectFieldType();
  removeSelectedWithCreateButton();
  activityFieldFilterChange();
});
