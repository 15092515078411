const updateActivityFormAndFieldOptions = () => $('#publication_activity_form').on('change', function() {
  var activity_form_id = $(this).val();
  var publication_form_mapping_id = $('#publication_form_mapping_id').val();

  $.ajax({
    url: "/publication_form_mappings/" + publication_form_mapping_id + "/activity_field_options",
    method: "GET",
    dataType: "script",
    data: { activity_form_id: activity_form_id }
  });
});

const updateFieldTypeAndFormValues = () => $('#publication_activity_field').on('change', function() {
  const field_type_id = $(this).val();
  const publication_form_mapping_id = $('#publication_form_mapping_id').val();
  
  return $.ajax({
    url: "/publication_form_mappings/" + publication_form_mapping_id + "/activity_form_field_values",
    method: "GET",
    dataType: "script",
    data: { 
      field_type_id: field_type_id
    },
  });
});

$(document).on('turbolinks:load', function() {
  updateActivityFormAndFieldOptions();
  updateFieldTypeAndFormValues();
});

window.updateActivityFormAndFieldOptions = updateActivityFormAndFieldOptions;
window.updateFieldTypeAndFormValues = updateFieldTypeAndFormValues;
