window['powerbi-client'] = require('powerbi-client');

const setupPowerBi = function($powerBiReport) {
  if ($powerBiReport.data('powerbi') === 'off') { return; }
  if ($powerBiReport.data('powerbi') === 'loading') { generateTokenAndUrl($powerBiReport); }
  embedReport($powerBiReport);
};

var generateTokenAndUrl= $powerBiReport => $.ajax({
  type: 'GET',
  url: $powerBiReport.data('load-report-url'),
  dataType: 'html',
  data: {
    report_guid: $powerBiReport.data('report-guid')
  },
  success(html) {
    $('#power-bi-report').replaceWith(html);
    embedReport($('#power-bi-report'));
  },
  error(response) {
    $('#power-bi-report').replaceWith(response.responseText);
  }
});

var embedReport = function($powerBiReport){
  const {
    models
  } = window['powerbi-client'];
  const embedConfiguration = {
    type: 'report',
    tokenType: models.TokenType.Embed,
    accessToken: $powerBiReport.data('access-token'),
    embedUrl: $powerBiReport.data('embed-url'),
    permissions: models.Permissions.Read,
    settings: {}
  };

  powerbi.embed($powerBiReport.get(0), embedConfiguration);
};

$(document).on('turbolinks:load', () => $('#power-bi-report').each(function() {
  setupPowerBi($(this));
}));
