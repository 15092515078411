const filterCourseOfferingResults = () => $('#course_reports_filters').on('change', function(e) {
  filterResults(e);
});

const clearfilters = () => $('.clear-filters').on('click', function(e){
  $('#course-select').val('').trigger('chosen:updated');
  $('#term-select').val('').trigger('chosen:updated');
  $('#org-select').val('').trigger('chosen:updated');
  $('#faculty-select').val('').trigger('chosen:updated');
  filterResults(e);
});

const filterResults = function(e) {
  e.preventDefault();
  const courseId = $('#course-select').val();
  const orgId    = $('#org-select').val();
  const userId   = $('#faculty-select').val();
  const termId   = $('#term-select').val();
  const cfeId    = $('#course-feedback-event-select').val();
  $.ajax({
    type: 'GET',
    url: $('#course_reports_filters').attr('action'),
    dataType: 'script',
    data: {
      organization_id: orgId,
      course_id: courseId,
      user_id: userId,
      term_id: termId,
      course_feedback_event_id: cfeId
    }
  });
};

const initChosen = function() {
  $('#course-feedback-event-select').chosen({ width: '100%'});
  $('#course-feedback-event-select').attr('aria-label', 'Filter by Course Feedback Event');
  $('#faculty-select').chosen({ width: '100%'});
  $('#faculty-select').attr('aria-label', 'Filter by Faculty Member');
  $('#term-select').chosen({ width: '100%'});
  $('#term-select').attr('aria-label', 'Filter by Term');
  $('#org-select').chosen({ width: '100%'});
  $('#org-select').attr('aria-label', 'Filter by Academic Unit');
  $('#course-select').chosen({ width: '100%'});
  $('#course-select').attr('aria-label', 'Filter by Course');
};

$(document).on('turbolinks:load', function() {
  filterCourseOfferingResults();
  clearfilters();
  initChosen();
});

$(document).on('turbolinks:before-cache', function() {
  $('#org-select').chosen('destroy');
  $('#course-select').chosen('destroy');
  $('#term-select').chosen('destroy');
  $('#faculty-select').chosen('destroy')
  $('#course-feedback-event-select').chosen('destroy')
});
