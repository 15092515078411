const sortableActivityReviewCycleSteps = () => $(document).on('sortupdate', '.sortable#activity-review-cycle-steps', function(e, ui) {
  let ids = $(this).sortable('toArray');
  ids = $.map(ids, id => id.replace(/activity_review_cycle_step_/,'').replace(/_row/,''));
  $.ajax({
    type: 'PUT',
    url: $(this).data('url'),
    dataType: 'html',
    data: { activity_review_cycle_step_ids: ids }});
});

const editActivityReviewCycleStep = function() {
  $(document).on('click', '.edit-step', function() {
    const url = $(this).parent('div').data('url');
    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'script'
    });
  });
};

const reviewStepFormBody = () => $('#review-step-type-select').on('change', function() {
  $.ajax({
    type: 'GET',
    url: $(this).data('url'),
    dataType: 'script',
    data: { step_type: $(this).val(), cycle_id: $(this).data('cycleId') }
  });
});

$(document).on('turbolinks:load', function() {
  sortableActivityReviewCycleSteps();
  editActivityReviewCycleStep();
  reviewStepFormBody();
});
