const watchForIconClicks = function() {
  $('.create-activity-container').on('click', function(e) {
    const $span = $(e.target).parents('span');
    if ($span.hasClass('add-field-to-form')) {
      addFieldToForm($span);
    } else if ($span.hasClass('remove-field-from-form')) {
      removeFieldFromForm($span);
    } else if ($span.hasClass('edit-field-settings')) {
      editActivityFormsFieldTypeSettings($span);
    }
  });
};

const hideFormTooltips = function() {
  $('.tooltip').tooltip('hide');
};

const previewActivityForm = elem => $('.preview-activity-form').on('click', function() {
  $.ajax({
    type: 'GET',
    url: $(this).data('url'),
    dataType: 'html',
    success(html) {
      $('#previewActivityForm .modal-body').html(html);
      $('#preview-title').html($('#title').html());
      if ($('#preview a').is(':visible')) {
        location.href = '#preview';
      }
    }
  });
});

var addFieldToForm = function($elem) {
  const fieldTypeId = $elem.data('id');
  const $icon = $elem.children('i');
  $.ajax({
    type: 'POST',
    url: $elem.data('source'),
    dataType: 'html',
    success(html) {
      $('#activity-form-builder').append(html);
      bottomButtonsControl();
      $elem.removeClass('add-field-to-form');
      $icon.removeClass('fa-arrow-right');
      $icon.addClass('fa-check-circle green-text');
      hideFormTooltips();
      $elem.tooltip().tooltip('disable');
      $(document).tooltip({ selector: '[data-toggle="tooltip"]' });
    }
  });
};

var removeFieldFromForm = function($elem) {
  const fieldTypeId = $elem.data('id');
  $.ajax({
    type: 'DELETE',
    url: $elem.data('source'),
    dataType: 'html',
    success(html) {
      $elem.parents('.card').remove();
      bottomButtonsControl();
      const $icon = $('[data-id="check-'+ fieldTypeId + '"]').children('i');
      $icon.removeClass('fa-check-circle green-text');
      $icon.addClass('fa-arrow-right');
      const $span = $('[data-id="'+ fieldTypeId + '"]');
      $span.addClass('add-field-to-form');
      hideFormTooltips();
    }
  });
};

var editActivityFormsFieldTypeSettings = function(elem) {
  const $this = $(elem);
  const fieldTypeId = $this.data('id');
  $.ajax({
    type: 'GET',
    url: $this.data('source'),
    dataType: 'html',
    success(html) {
      $('#editAfftSettings .modal-body').html(html);
      $('#editAfftSettings').modal({show: true, backdrop: 'static'});
    }
  });
};

const sortableCards = function() {
  $('#form-builder .sortable').sortable({update(e, ui) {
    $.ajax({
      type: 'PUT',
      url: $(this).data('url'),
      dataType: 'html',
      data: $(this).sortable('serialize')
    });
  }
  });
};

const sortableFields = function() {
  $('#citations .sortable').sortable({update(e, ui) {
      const id = $(this).data('id');
      $('.popover').popover('hide');
      let ids = $(this).sortable('toArray');
      ids = $.map(ids, id => id.replace(/activity_forms_field_type_/, '').replace(/_citation_\d+/, ''));
      $.ajax({
        type: 'PUT',
        url: $(this).data('url'),
        dataType: 'html',
        data: { activity_forms_field_type: ids },
        success(html) {
          $('#preview_' + id).html(html);
        }
      });
    }
  });
};

var bottomButtonsControl = function() {
  if ($('#activity-form-builder').height() >= 420) {
    $('#bottom-buttons').attr({hidden: false});
  } else {
    $('#bottom-buttons').attr({hidden: true});
  }
};

const categorySelect = function() {
  addCategorySelector('#category-select', '#subcategory-select');
};

const watchForInputChanges = function() {
  $('.popover').find('input[type="text"]').on('focusout', function() {
    updateCitationOutline($(this));
  });
  $('.popover').find('.collaborators_format input:radio').on('change', function() {
    updateCitationOutline($(this));
  });
};

const watchForStyleChanges = function() {
  $('.popover').find('.btn-group span').on('click', function() {
    const $input = $('#citation_outline_' + $(this).data('field'));
    if ($input.val() === 'true') {
      $input.val('false');
      if ($(this).hasClass('btn-outline-secondary')) {
        $(this).removeClass('active-option');
      } else {
        $(this).removeClass('active-hidden-option');
      }
    } else {
      $input.val('true');
      if ($(this).hasClass('btn-outline-secondary')) {
        $(this).addClass('active-option');
      } else {
        $(this).addClass('active-hidden-option');
      }
    }

    updateCitationOutline($(this));
  });
};

var updateCitationOutline = function($this) {
  const $pop           = $this.parents('.popover-data');
  const $form          = $this.parents('form');
  const citation_id    = $pop.data('citationId');
  const sortable_id    = '[data-id="citation_' + citation_id + '"]';
  const citation_order = $(sortable_id).sortable('toArray', { attribute: 'data-id' });
  $.ajax({
    type: 'PUT',
    url: $this.parents('.popover-data').data('url'),
    dataType: 'html',
    data: {
      activity_forms_field_type: citation_order,
      afft_id:                   $form.find('#citation_outline_id').val(),
      citation_outline: {
        after_text:       $form.find('#citation_outline_after_text').val(),
        before_text:      $form.find('#citation_outline_before_text').val(),
        bold:             $form.find('#citation_outline_bold').val(),
        italic:           $form.find('#citation_outline_italic').val(),
        underline:        $form.find('#citation_outline_underline').val(),
        hidden:           $form.find('#citation_outline_hidden').val(),
        collaborators:    $form.find('.collaborators_format input:checked').val()
      }
    },
    success(html) {
      $('#preview_citation_' + citation_id).html(html);
    }
  });
};

const popoverOutlineForm = function() {
  $(document).on('show.bs.popover', () => $('.popover').popover('hide'));

  $(document).on('shown.bs.popover', function() {
    watchForInputChanges();
    watchForStyleChanges();
    $('.popover').addClass('popover-loaded');
  });

  // due to the way popovers work, the html of the popover gets created/destroyed
  // on open and close which was preventing the inputs from retaining their values
  // without refreshing. this updates the html the popover gets created from to
  // keep the values in sync
  $(document).on('hide.bs.popover', function() {
    const $popover = $('.popover');
    $popover.find('input').each(function(i, input) {
      const $input = $(input);
      $input.attr('value', $input.val());
      $input.attr('checked', $input.is(':checked'));
    });

    const target_id = $(this).find('.popover-data').data('target');
    $(target_id + ' > span').attr('data-content', $('.popover-body').html());
  });
};


var addCategorySelector = (category_selector, subcategory_selector) => $(category_selector).on('change', function() {
  const cat_id = $(this).val();
  return $.ajax({
    type: 'GET',
    url: $(this).data('url'),
    dataType: 'html',
    data: { id: cat_id },
    success(html) {
      if (html === "") {
        $(subcategory_selector).html('');
      } else {
        $(subcategory_selector).html(html);
      }
    }
  });
});

const copyActivityFormAction = () => $('.copy-activity-form-action').click(function() {
  $.ajax({
    type: 'GET',
    url: $(this).data('show'),
    dataType: 'html',
    success(html) {
      $('#copyActivityForm').replaceWith(html);
      addCategorySelector('#category-select-copy', '#subcategory-select-copy');
      $('#copyActivityForm').modal();
    }
  });
});

const sendIdToEditModal = () => $('.rename-activity-form-modal').click(function() {
  const url = $(this).data('url');
  $('#rename-activity-form').attr('action', url);
});

const revealOptionText = () => $(document).on('click', '.field-option-name', function() {
  var id = $(this).attr('data-id');
  var edit_div = document.querySelector("#edit_" + id);
  if(edit_div != null){
    $("#name_" + id).addClass('hidden');
    $("#edit_" + id).removeClass('hidden');
  }
});

const showOptionText = () => $(document).on('submit', '.field-option-name', function() {
  var id = $(this).attr('data-id');
  $("#edit_" + id).addClass('hidden');
  $("#name_" + id).removeClass('hidden');
});

const disableHelpText = () => $(document).on('change', '#activity_forms_field_type_enable_academic_date', function () {
  if ($(this).is(":checked")) {
    $('#activity_forms_field_type_help_text').attr('readonly', true);
    $('#activity_forms_field_type_help_text').val('Contact your administrator if you are missing the correct academic year and term.');
  } else {
    $('#activity_forms_field_type_help_text').attr('readonly', false);
    $('#activity_forms_field_type_help_text').val('');
  }
});

const activityFieldFilterChange = () => $(document).on('change', '#activity_field_form_ids, #tag_form_ids', function(){
  const filter_query = $('.search-input').val();
  const filter_types = $('#activity_field_form_ids').val();
  const filter_tags = $('#tag_form_ids').val();
  var form_data = document.getElementById('filter_form');
  const filter_form = form_data.dataset.formId;

  $.ajax({
    type: 'GET',
    url: '/search/field_types/activity_form_search_and_filter',
    dataType: 'html',
    data: { query: filter_query, filter_types, filter_tags, filter_form },
    success(html) {
      $('#field_types').replaceWith(html);
    }
  });
});

const searchFieldTypesByName = () => $(document).on('keyup', '.create-activity-container .search-input', function(e) {
  if (e.key == 'Meta') return;

  const filter_types = $('#activity_field_form_ids').val();
  const filter_tags = $('#tag_form_ids').val();
  var form_data = document.getElementById('filter_form');
  const filter_form = form_data.dataset.formId;

  $.ajax({
    type: 'GET',
    url: '/search/field_types/activity_form_search_and_filter',
    dataType: 'html',
    data: {query: $(this).val(), filter_types, filter_tags, filter_form},
    success(html) {
      $('#field_types').replaceWith(html);
    }
  });
});

const adjustScroll = function() {
  if($('.create-activity-container').is(':visible') || $('.activity_field_center_index').is(':visible')) {
    $('#main').height($(window).height() - 285);
    $('#main').css('overflow-y', 'hidden');
    $('.pad-footer').css('margin-bottom', 0)
  }
};

$(document).on('turbolinks:load', function() {
  sortableCards();
  sortableFields();
  watchForIconClicks();
  previewActivityForm();
  categorySelect();
  copyActivityFormAction();
  sendIdToEditModal();
  revealOptionText();
  showOptionText();
  disableHelpText();
  activityFieldFilterChange();
  searchFieldTypesByName();
  adjustScroll();
});

$(document).ready(function() {
  bottomButtonsControl();
  popoverOutlineForm();

  $('#createActivityForm').on('shown.bs.modal', function() {
    $('input:text', this).focus();
  });
});
