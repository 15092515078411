/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
window.Rails = Rails;
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'chosen-js'
import 'jquery'
import 'jquery-ui'
import 'bootstrap'
import 'summernote/dist/summernote-bs4'
window['powerbi-client'] = require('powerbi-client');

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require('chart.js/dist/chart.js')
require("trix")
require("@rails/actiontext")
require('jquery-ui-dist/jquery-ui.css')
require('src/accreditation_reportings.js')
require('src/activities.js')
require('src/activity_forms.js')
require('src/activity_review_cycles.js')
require('src/activity_review_cycle_steps.js')
require('src/admin/configurations.js')
require('src/biosketch_builders.js')
require('src/candidate_response.js')
require('src/categories.js')
require('src/citations.js')
require('src/committees.js')
require('src/courses.js')
require('src/cv_templates.js')
require('src/data_exporter.js')
require('src/datepicker/js/bootstrap-datepicker')
require('src/dossiers/documents.js')
require('src/evaluators.js')
require('src/faculty_cvs.js')
require('src/far_templates.js')
require('src/formatted_reports.js')
require('src/headings.js')
require('src/home.js')
require('src/imports.js')
require('src/kase_templates.js')
require('src/kases.js')
require('src/kases/steps.js')
require('src/kases/packets/sections.js')
require('src/navigation.js')
require('src/powerbi.js')
require('src/orcid.js')
require('src/tags.js')
require('src/users.js')
require('src/wizards.js')
require('src/banner.js')
require('src/faculty_activity_reports.js')
require('src/surveys.js')
require('src/course_feedback_events.js')
require('src/course_catalogs.js')
require('src/email_templates.js')
require('src/field_types.js')
require('src/help_videos.js')
require('src/data_transformers.js')
require('src/roles.js')
require('src/activity_templates.js')
require('src/course_section_reports.js')
require('src/survey_event_reports.js')
require('src/instructor_summary_reports.js')
require('src/accreditation_reportings.js')

function initTooltips(){
  $(document).tooltip({ selector: '[data-toggle="tooltip"]' });
}

function hideTooltips(){
  $('.tooltip').tooltip('hide');
}

function initDatePicker(){
  if($('.term-dropdowns').length == 1) {return};

  $('.datepicker').datepicker({
    format: "yyyy/mm/dd",
    changeMonth: true,
    changeYear: true,
    showOtherMonths: true,
    selectOtherMonths: true,
    yearRange: '-100:+50'
  });
}

function initPopovers(){
  $('[data-toggle="popover"]').popover({
    html: true,
    sanitize: false // TODO: This is a security concern. https://blog.getbootstrap.com/2019/02/13/bootstrap-4-3-1-and-3-4-1/
  });
}

function initTreeCollapsable() {
  $('#tree-source .tree-collapse-button').click(function () {
    const icon = $(this).find('i');
    if (icon.hasClass('fa-chevron-down')) {
      icon.addClass('fa-chevron-right').removeClass('fa-chevron-down')
    } else {
      icon.addClass('fa-chevron-down').removeClass('fa-chevron-right')
    }
  });
}

function initAlertTimeout() {
  setTimeout(function(){
    $('.alert').slideUp(500);
  }, 20000);
};

function initSortable(){
  $('.sortable').sortable();
}

function auto_grow(element) {
  if (element) {
    element.style.height = "5px";
    element.style.height = (element.scrollHeight)+"px";
  }
}

function refreshFileFunctions(){
  $('#fileInput').on('change', function(){
    const chosenFilename = $(this).val();
    $('.custom-file-label').html(chosenFilename)
  })
}

function focusInputOnModalOpen(){
  $(document).on('shown.bs.modal', function(){
    $('.modal .modal-body').find(':input:visible:first').focus();
  })
}

function chevronToggle() {
  $(document).delegate('.chevron_toggle', 'click', function() {
    const $icon = $(this).find('i');
    $icon.toggleClass('fa-chevron-down fa-chevron-right');
  });
};

document.addEventListener("trix-file-accept", event => {
  event.preventDefault() // prevent file upload via trix editor
})

//rails 5 document.ready
document.addEventListener("turbolinks:load", function() {
  initTooltips();
  initDatePicker();
  initPopovers();
  initTreeCollapsable();
  initSortable();
  focusInputOnModalOpen();
  chevronToggle();
  initAlertTimeout();
  $.rails.refreshCSRFTokens();
  auto_grow($('#document_type_form_object_description')[0]);
});

document.addEventListener('turbolinks:before-cache', function() {
  hideTooltips()
  if (document.body.classList.contains('modal-open')) {
    $('.modal')
      .hide()
      .removeAttr('aria-modal')
      .attr('aria-hidden', 'true')
    $('.modal-backdrop').remove()
    $('body').removeClass('modal-open')
  }
})

$(document).ajaxError(function(event, jqxhr, settings, thrownError) {
  if (thrownError === "Unauthorized") {
    window.location.reload()
  }
})
