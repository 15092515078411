const selectAllRecords = () => $('.orcid-select-all').on('click', function() {
  $('.orcid-records :checkbox').prop('checked', $(this).prop('checked'));
});

document.addEventListener('turbolinks:load', function() {
  selectAllRecords();

  window.onbeforeunload = function() {
    orcid_regex = window.location.pathname.match(/\/orcid\/import\/\w+\/\d+/)
    if (orcid_regex != null && orcid_regex.length > 0) {
      return true;
    }
  };
});
