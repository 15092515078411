const selectActivityReviewCycle = () => $('.review-cycle').on('click', function() {
  $('.review-cycle-row').removeClass('selected');
  $(this).parent('.review-cycle-row').addClass('selected');
  $.ajax({
    type: 'GET',
    url: '/activity_review_cycles',
    dataType: 'script',
    data: { activity_review_cycle_id: $(this).parent().data('activity-review-cycle-id') }});
});

const initOrgsChosen = function() {
  $('#activity_review_cycle_organizations')
    .chosen({hide_results_on_select: true, width: '100%', inherit_select_classes: true});
  $('#activity_review_cycle_activity_template_id')
    .chosen({hide_results_on_select: true, width: '100%', inherit_select_classes: true});
};

const createStep = function() {
  $(document).on('click', '.create-step', function() {
    const $parentDiv = $('#parent_info');
    const $this      = $(this).parent('div');
    const id         = $this.data('id');
    const name       = $this.data('name');
    const label      = $this.data('label');
    $parentDiv.children('span').html(label + name);
    $parentDiv.children('input').val(id);
    $('#createStep').modal({show: true, backdrop: 'static'});
  });
};

$(document).on('turbolinks:load', function() {
  selectActivityReviewCycle();
  initOrgsChosen();
  createStep();
});

$(document).on('turbolinks:before-cache', () => $('#activity_review_cycle_organizations').chosen('destroy'));
