/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const sortSubsectionsAndDocs = function() {
  $('.section-sortable-block').each(function() {
    const parent_id = '#' + this.id;
    $(this).sortable({
      update: updateOrder,
      containment: 'parent',
      tolerance: 'pointer'
    });
    $(this).disableSelection();
    $(this).children().children('.packets_subsection').sortable({
      connectWith: '.connectedSortable',
      update: updateOrder,
      containment: $(this).closest('.subsections-sortable-container')
    });
    $(this).children().children('.packets_subsection').disableSelection();
    return $(this).siblings('.packet_section').each(function() {
      return $(this).children('.packets_subsection').sortable({
        connectWith: '.connectedSortable',
        update: updateOrder,
        containment: $(this).closest('.subsections-sortable-container')
      });
    });
  });
};

const deleteDocumentType = () => $('.delete-kase-document-type').on('click', function() {
  var doc_type_id = $(this).data('id');
  var documentType = $(this).closest('[id*="document-types-"]');
  $.ajax({
    type: 'DELETE',
    url: $(this).data('url'),
    dataType: 'html',
    data: {
      doc_type_id: doc_type_id,
      section_id: $(this).data('section_id'),
    },
    success: successCallback(documentType),
  });

  function successCallback(documentType) {
    $('#packets_section_document_type_' + doc_type_id).remove();
    if ($('#packets_section_document_type_' + doc_type_id).length > 0) {
      $('#packets_section_document_type_' + doc_type_id)[0].remove();
    }
    if (documentType.find('ul').children().length == 0) {
      documentType.find('.empty-message').removeClass('hidden');
    } else {
      documentType.find('.empty-message').addClass('hidden')
    }
  }
});

var updateOrder = function() {
  const data = {};
  const subsections = $('.section-sortable-block').children('div');
  let index = 0;
  while (index < subsections.length) {
    const subsection = subsections[index];
    const subsection_doc_elements = $(subsection).find('ul').children('li').children();
    data[subsection.id] = subsection_doc_elements.map(function() { return $(this).attr('id'); }).get().join(', ');
    index++;
  }
  $.ajax({
    type: 'PUT',
    url: $('.section-sortable-block').data('url'),
    dataType: 'html',
    data: {
      ordered_data: {
        subsections_and_docs: data
      }
    }
  });
};

const initChosen = function() {
  $('#packet_user_ids').chosen({width: '100%'});
  $('#packet_user_ids_chosen .chosen-search-input').attr('aria-label', 'Select Internal Users');
};

const preventSectionDrag = () => $('.prevent-section-drag').on('mousedown', e => false);

const updateCandidateChecked = () => $('#candidate_checkbox').on('change', function() {
  $.ajax({
    type: 'PUT',
    url: $(this).data('url'),
    dataType: 'html',
    data: { candidate_checked: $(this).prop('checked') }
  });
})

$(document).on('turbolinks:load', function() {
  initChosen();
  sortSubsectionsAndDocs();
  deleteDocumentType();
  updateCandidateChecked();
  return preventSectionDrag();
});

window.deleteDocumentType = deleteDocumentType;
