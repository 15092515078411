const formattedReportsinitChosen = function() {
  $(".formatted_reports__downloads #org-select").chosen({ width: '50%', inherit_select_classes: true });
  $('.formatted_reports__downloads #org-select .chosen-search-input').attr('aria-label', 'Select Organizations');
};

const activityDateOptions = function() {
  if ($('#formatted_report_activity_date_option').val() === 'Date Range') {
    $('#activity_date_range_options').attr('hidden', false);
  }
  $('#formatted_report_activity_date_option').on('change', function() {
    if ($(this).val() !== 'Date Range') {
      $('#activity_date_range_options').attr('hidden', true);
    } else {
      $('#activity_date_range_options').attr('hidden', false);
    }
  });
};

const summarySubmitToggle = () => {
  if($('.summary-type-select').val() == 'sum') {
    if($('.summary-target-select').val()) {
      $('.submit_summary').prop('disabled', false);
    }
  }else{
    $('.submit_summary').prop('disabled', false);
  }
}

const sectionFilterOptions = function() {
  $(document).on("change", ".filter-target-select", function(){
    var field_type_id = $(this).val();

    $.ajax({
      url: "/activity_sections_filters/new",
      method: "GET",
      dataType: "JSON",
      data: {field_type_id: field_type_id},
      success: function (options) {
        $(".filter-value-select").empty();
        for(var i=0; i < options.length; i++){
          $(".filter-value-select").append('<option value="' + options[i]["id"] + '">' + options[i]["name"] + '</option>');
        }

        return options;
      }
    });
  });
};

const toggleSummaryGrouping = () => $('.enable-summary-group-by').on('click', function() {
  const value = $(this).prop('checked');
  $(this).parent().find('.group-target-select').first().prop('disabled', !value);
});

const editFilterOptions = function() {
  $(document).on("change", ".filter-target-edit", function(){
    var activity_section_id = $('#section_filter_modal').attr('data-id')
    var field_type_id = $(this).val();
    $.ajax({
      url: "/activity_sections_filters/" + activity_section_id + "/select_fields_options",
      method: "GET",
      dataType: "JSON",
      data: {
        activity_section_id: activity_section_id,
        field_type_id: field_type_id
      },
      success: function (options) {
        $("#filter-value-edit").empty();
        for(var i=0; i < options.length; i++){
          $("#filter-value-edit").append('<option value="' + options[i]["id"] + '">' + options[i]["name"] + '</option>');
        }

        return options;
      }
    });
  });
}

const editSectionFilter = () => $(document).on('click', '.edit-section-filter', function() {
  const url = $(this).data('url');
  const id = $(this).data('id');
  $.ajax({
    type: 'GET',
    url: url,
    dataType: 'html',
    data: { activity_sections_filter_id: id },
    success(html) {
      $('#editSectionFilter .modal-body').html(html);
      $('#editSectionFilter').modal({show: true, backdrop: 'static'});
    }
  });
});

const revealSummaryOptions = function() {
  $('.summary-type-select').on('change', function() {
    const dropdown = $(this);
    const url = $(this).data('url');
    const summary_type = $(this).val();
    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'html',
      data: { summary_type: summary_type },
      success(html) {
        if (summary_type == 'sum') {
          $('.group-target-options').html('');
          summarySubmitToggle();
          dropdown.closest('.summary-dropdowns').find('.summary-target-options').first().html(html);
          revealGroupOptions();
        } else {
          $('.group-target-options').html('');
          summarySubmitToggle();
          dropdown.closest('.summary-dropdowns').find('.summary-target-options').first().html('');
          dropdown.closest('.summary-dropdowns').find('.group-target-options').first().html(html);
          toggleSummaryGrouping();
        }
      }
    });
  });
};

const revealGroupOptions = function() {
  $('.summary-target-select').on('change', function() {
    $('.group-target-options').html('');
    const dropdown = $(this);
    const url = $(this).data('url');
    const summary_target = $(this).val();
    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'html',
      data: { summary_target: summary_target },
      success(html) {
        summarySubmitToggle();
        dropdown.closest('.summary-dropdowns').find('.group-target-options').first().html(html);
        toggleSummaryGrouping();
      }
    });
  });
};

const editSummary = () => $('.sortable').on('click', '.edit-summary', function(e) {
  e.preventDefault();
  const url = $(this).data('templateUrl');
  const modalTitle = 'Edit Summary';

  $.ajax({
    type: 'GET',
    url: url,
    data: {source_type: $(this).data('sourceType'), activity_section_id: $(this).data('sectionId')},
    dataType: 'html',
    success(html) {
      $('#createAndEditSummary .modal-body').html(html);
      $('#createAndEditSummary .modal-title').html(modalTitle);
      revealSummaryOptions();
      $('#createAndEditSummary').modal({show: true, backdrop: 'static'});
      toggleSummaryGrouping();
    }
  });
});

const deleteSummary = () => $('.sortable').on('click', '.delete-template-summary', function(e) {
  e.stopImmediatePropagation();
  if (confirm('Are you certain you wish to delete this summary?')) {
    const url = $(this).data('url');
    const id = $(this).data('id');
    $.ajax({
      type: 'DELETE',
      url: url,
      dataType: 'html',
      data: { id: id },
      success(html) {
        $('#activity_template_summary_' + id).remove();
        $('.tooltip').tooltip('hide');
      }
    });
  }
});

const updateStyleAndFormat = function() {
  $('.cv-body').css('font-family', $('#formatted_report_font_style_id option:selected').text());
  $('.document-section').css('font-size', $('#section_font_size').val() + "px");
  $('#formatted_report_section_font_size').val($('#section_font_size').val());
  $('.document-subsection').css('font-size', $('#subsection_font_size').val() + "px");
  $('#formatted_report_subsection_font_size').val($('#subsection_font_size').val());
  $('.document-activity').css('font-size', $('#formatted_report_activity_font_size').val() + "px");
  $('.document-activity').css('line-height', $('#formatted_report_activity_line_height').val());
};

const loadStyles = () => $('#FormattedReport .btn.style').on('click', function(event) {
  event.preventDefault();
  const $button = $(this);
  const header = $(this).data('header');
  const style = $(this).data('style');
  if ($('#formatted_report_' + header + '_' + style).val() === 'true') {
    $('.document-' + header).removeClass(style);
    $('#formatted_report_' + header + '_' + style).val('false');
    $button.removeClass('active-option');
  } else {
    $('.document-' + header).addClass(style);
    $('#formatted_report_' + header + '_' + style).val('true');
    $button.addClass('active-option');
  }
});

const setupStyleAndFormat = function() {
  $('.style-and-format :input').on('input', () => updateStyleAndFormat());
  updateStyleAndFormat();
};

const setReportTemplateId = function() {
  $('#formatted_report_ids').on('change', function() {
    const report_id = $(this).val();
    $('#formatted_report_create_from_template').val(report_id)
  });
};

const enableTemplateDropdown = function() {
  $('#formatted_report_create_from_template').on('click', function() {
    $('#formatted_report_ids').removeAttr('disabled');
  });
};

const disableTemplateDropdown = function() {
  $('#formatted_report_create_from_blank_template').on('click', function() {
    $('#formatted_report_ids').attr('disabled', 'disabled');
  });
};

$(document).on('turbolinks:load', function() {
  editSummary();
  activityDateOptions();
  formattedReportsinitChosen();
  sectionFilterOptions();
  editSectionFilter();
  editFilterOptions();
  revealSummaryOptions();
  deleteSummary();
  setupStyleAndFormat();
  loadStyles();
  setReportTemplateId();
  enableTemplateDropdown();
  disableTemplateDropdown();
});

$(document).on('turbolinks:before-cache', () => $('.formatted_reports__downloads #org-select').chosen('destroy'));
