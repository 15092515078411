const loadTableForTab = (route) => {
  $.ajax({
    type: 'GET',
    url: route,
    dataType: 'script',
  });
};

const loadTabOnPageLoad = () => {
  if ($('.course_catalogs-show').is(':visible')){
    loadTableForTab($('.nav-tabs .nav-item.nav-link.active').data('route'));
  }
};

const watchForTabChange = () => $('.course_catalogs-show a[data-toggle="tab"]').on('shown.bs.tab', function (event) {
  loadTableForTab($(event.target).data('route'))
});

const scrollListener = (element) => element.on('scroll', function(e) {
  var scrollDiv = element[0];
  var scrollHeight = scrollDiv.scrollHeight - scrollDiv.scrollTop - 1;
  var scrollBottom = scrollDiv.clientHeight;
  var nextPage = $('.tab-pane.active .load-more').data('nextPage');

  if(scrollHeight <= scrollBottom && nextPage) {
    var page = "&page=" + nextPage;
    loadTableForTab($('.nav-tabs .nav-item.nav-link.active').data('route') + page);
  }
});

const toggleCollapseForCourses = () => $(document).on('click', '.toggle-course-offerings', function(e) {
  var target = $(this).data('target');
  $(target).toggleClass('hidden');
});

$(document).on('turbolinks:load', function() {
  watchForTabChange();
  loadTabOnPageLoad();
  scrollListener($('.tab-content'));
  toggleCollapseForCourses();
});
