const setFontFamilyOnLoad = () => $('.document').css('font-family', $('.document').data('font-family'));

const previewCvTemplate = elem => $('.preview-cv-template').on('click', function() {
  const title             = $(this).data('title');
  const docx_download_url = $(this).data('docxDownloadUrl');
  const pdf_download_url  = $(this).data('pdfDownloadUrl');
  $.ajax({
    type: 'GET',
    url: $(this).data('url'),
    dataType: 'html',
    success(html) {
      $('#previewCv .modal-body').html(html);
      $('#previewCv .modal-title').html(title);
      $('#previewCv .modal-foot #docx_download').attr('href', docx_download_url);
      $('#previewCv .modal-foot #pdf_download').attr('href', pdf_download_url);
      $('#previewCv').modal({show: true, backdrop: 'static'});
    }
  });
});

$(document).on('turbolinks:load', function() {
  previewCvTemplate();
  setFontFamilyOnLoad();
});
