const toggleMobileView = () => $('#mobile-view').on('click', function() {
  toggleMobileButton();
  $('#survey-viewport iframe').removeClass('desktop-iframe');
  $('#survey-viewport iframe').addClass('mobile-iframe');
});

const toggleDesktopView = () => $('#desktop-view').on('click', function() {
  toggleDesktopButton();
  $('#survey-viewport iframe').removeClass('mobile-iframe');
  $('#survey-viewport iframe').addClass('desktop-iframe');
});

const toggleMobileButton = () => {
  $('#desktop-view').removeClass('disabled');
  $('#desktop-view').attr('aria-pressed', false);
  $('#mobile-view').addClass('disabled');
  $('#mobile-view').attr('aria-pressed', true);
};

const toggleDesktopButton = ()  => {
  $('#mobile-view').removeClass('disabled');
  $('#mobile-view').attr('aria-pressed', false);
  $('#desktop-view').addClass('disabled');
  $('#desktop-view').attr('aria-pressed', true);
}

const initChosen = function() {
  $("#configure-tab #org-select").chosen({ width: '100%', inherit_select_classes: true });
  $('#configure-tab #org-select .chosen-search-input').attr('aria-label', 'Select Organizations');
  $("#configure-tab #course-select").chosen({ width: '100%', inherit_select_classes: true });
  $('#configure-tab #course-select .chosen-search-input').attr('aria-label', 'Select Courses');
};

const filterSurveyData = function() {
  $('#course_filters').on('submit', function(e){
    e.preventDefault();
    e.stopImmediatePropagation();

    const org_id = $('#org-select').val();
    const course_id = $('#course-select').val();
    const user_id = $('#faculty-select').val();
    const params = '?organization_id=' + org_id +  '&course_id=' + course_id + '&user_id=' + user_id;
    window.location = $(this).attr('action') + params
  })
}

const updateFilterDropdowns = () => $('#course_filters .custom-select').on('change', function(e) {
  e.preventDefault();
  const orgId = $('#org-select').val();
  const courseId = $('#course-select').val();
  const userId = $('#faculty-select').val();
  var cfeId = $(this).data('courseFeedbackEventId');
  $.ajax({
    type: 'GET',
    dataType: 'script',
    url: '/course_feedback_events/'+ cfeId + '/update_filter_dropdowns',
    data: {organization_id: orgId, course_id: courseId, user_id: userId}
  })
});

const initOrganizationChosen = function() {
  $('#cfe_organization_filter').chosen({ width: '100%', placeholder_text_multiple: 'Select Academic Unit(s)' });
  $('#cfe_organization_filter .chosen-search-input').attr('aria-label', 'Select one or more ... ');
};

const initCourseChosen = function() {
  $('#cfe_course_filter').chosen({ width: '100%', placeholder_text_multiple: 'Select Course(s)' });
  $('#cfe_course_filter .chosen-search-input').attr('aria-label', 'Select one or more ... ');
};

const applyFiltersOnChange = () => $('#cfe-filter :input').on('change', function() {
  $(this).closest('form').submit();
});

const filterTermandCourses = () => $('#cfe-select-catalog, #cfe-select-term').on('change', function() {
  const courseFeedbackId = $('#course_feedback_id').val();
  const termId = $('#cfe-select-term').val();
  const catalogId = $('#cfe-select-catalog').val();
  $.ajax({
    type: 'GET',
    dataType: 'script',
    url: $(this).data('url'),
    data: { course_catalog_id: catalogId, id: courseFeedbackId, term_id: termId }
  })
});

const loadCarouselPaneOnScroll = () => $('#inProgressCourseFeedbackEvents').on('slid.bs.carousel', function (e) {
  var path = $(e.relatedTarget).data('path')

  $.ajax({
    type: 'GET',
    dataType: 'script',
    url: path
  })
});

const disableCarouselAutoScroll = function() {
  if ($('.course_feedback_events').is(':visible')) {
    $('.carousel').carousel({
      interval: false
    });
  }
}

const loadCourseList = function() {
  if($('#course-list').is(':visible')){
    var path = window.location.pathname.replace('/edit', '');
    $.ajax({
      type: 'GET',
      dataType: 'script',
      url: path + '/lists'
    })
  }
};

const scrollToTableOnLoad = function() {
  if($('.course_feedback_events-index').is(':visible') && window.location.search){
    $([document.documentElement, document.body]).animate({
        scrollTop: $("#cfe_table_scroll_point").offset().top
    }, 100);
  }
};

const triggerLoadCourseList = () => $('#courses').on('shown.bs.tab', function (e) {
  loadCourseList();
});

$(document).on('turbolinks:load', function() {
  loadCourseList();
  initChosen();
  filterTermandCourses();
  toggleMobileView();
  toggleDesktopView();
  filterSurveyData();
  updateFilterDropdowns();
  initOrganizationChosen();
  initCourseChosen();
  applyFiltersOnChange();
  disableCarouselAutoScroll();
  loadCarouselPaneOnScroll();
  scrollToTableOnLoad();
  triggerLoadCourseList();
});

$(document).on('turbolinks:before-cache', function() {
  $('#cfe_organization_filter').chosen('destroy');
  $('#cfe_course_filter').chosen('destroy');
  $('#configure-tab #org-select').chosen('destroy')
  $('#configure-tab #course-select').chosen('destroy')
});

window.updateFilterDropdowns = updateFilterDropdowns;
window.filterSurveyData = filterSurveyData;
window.filterTermandCourses = filterTermandCourses;
