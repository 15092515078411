const saveQuestionText = () => $('.edit-question').on('blur', function(e) {
  updateQuestion($(this));
});

const toggleScale = () => $('.surveys .scale-link').on('click', function(e) {
  e.preventDefault();

  let clickedIcon = $(this).find('.fa-square:first');
  const scale = $('.scale-icons .fa-square');
  let selectedIcons = scale.toArray().slice(0, clickedIcon.data('value'));
  let notSelectedIcons = scale.toArray().slice(clickedIcon.data('value'), scale.length);
  selectedIcons.forEach((element) => {
    element.classList.remove('far');
    element.classList.add('fas');
    $(element).attr('aria-label', `Square ${$(element).data('value')} - Selected`);
  });
  notSelectedIcons.forEach((element) => {
    element.classList.add('far');
    element.classList.remove('fas');
    $(element).attr('aria-label', `Square ${$(element).data('value')} - UnSelected`);
  })
  $('#question_answers').val(clickedIcon.data('value'));
});

const updateQuestion = ($element) => {
  questionId = getQuestionId($element);
  var url = '/questions/' + questionId
  $.ajax({
    type: 'PATCH',
    url: url,
    dataType: 'html',
    data: { description: $element.val() }
  })
}

const deleteAnswer = (element) => {
  var inputToDelete = element.closest('.row').find('.answer-option:first');
  var [ questionId, answerKey ] = inputToDelete.attr('id').split('-');
  var data = {
    question_id: questionId,
    answer_option: inputToDelete.val(),
    answer_key: answerKey
  };
  var url = '/questions/' + questionId + '/answer_options/' + answerKey;
  $.ajax({
    type: 'DELETE',
    url: url,
    dataType: 'script',
    data: data
  });
}

const updateAnswer = ($element) => {
  var [ questionId, answerKey ] = $element.attr('id').split('-');
  var data = {
    question_id: questionId,
    answer_option: $element.val(),
    answer_key: answerKey
  };
  var url = '/questions/' + questionId + '/answer_options/' + answerKey;
  $.ajax({
    type: 'PATCH',
    url: url,
    dataType: 'html',
    data: data
  });
}

const initializeAnswerDelete = () => $('.delete-answer-option').on('click', function(e) {
  e.preventDefault();

  var inputToDelete = $(this).closest('.row').find('.answer-option:first');
  var [ questionId, answerKey ] = inputToDelete.attr('id').split('-');
  var data = {
    question_id: questionId,
    answer_option: inputToDelete.val(),
    answer_key: answerKey
  };
  var url = '/questions/' + questionId + '/answer_options/' + answerKey;
  $.ajax({
    type: 'DELETE',
    url: url,
    dataType: 'script',
    data: data
  });
});

const addQuestionAnswerOption = () => $('.add-answer-option').on('click', function(e) {
  e.preventDefault();

  createQuestionAnswer($(this));
})

const createQuestionAnswer = (element) => {
  updateNumberOfElements(element, 1);
  var answerKey = getNumberOfOptions(element);
  const url = element.data('url');
  var defaultAnswer = 'Option' + ' ' + answerKey;
  $.ajax({
    type: 'POST',
    url: url,
    dataType: 'script',
    data: { answer_option: defaultAnswer, answer_key: answerKey },
    success: function($element) {
      var newAnswerInput = answerInputElement(url, answerKey, defaultAnswer, getQuestionId(element));
      newAnswerInput.hide().fadeIn(500);
      newAnswerInput.find('.answer-option:first').on('blur', function(e) {
        updateAnswer($(this));
      });
      newAnswerInput.find('.delete-answer-option:first').on('click', function(e) {
        deleteAnswer($(this));
      });
      element.closest('.card').find('.answer-options-list').append(newAnswerInput);
    }
  });
}

const answerInputElement = (url, answerKey, answerValue, questionId) => {
  var deleteCol = $("<div class='col-md-1'>");
  var deleteElement = deleteCol.append($('<a>', {'class': 'delete-answer-option', 'data-url': url}).append($('<i>', {'class': 'fas fa-times hover-pointer-cursor grey-text', 'aria-hidden': true, 'alt': 'delete'})));
  var addCol = $("<div class='col-md-11'>");
  var addElement = addCol.append($('<input>', {'id': questionId + '-' + answerKey, 'class': 'answer-option form-control', 'value': answerValue}));
  return $("<div class='row'></div>").append(deleteElement).append(addElement);
}

const saveQuestionAnswer = () => $('.answer-option').on('blur', function(e) {
  updateAnswer($(this));
})

const getQuestionId = (element) => {
  return element.closest('.card').find('.question-id').val();
}

const getNumberOfOptions = (element) => {
  return element.closest('.card').find('.number-of-options').val();
}

const updateNumberOfElements = (element, dif) => {
  var field = element.closest('.card').find('.number-of-options');
  field.val(parseInt(field.val()) + dif);
}
const sortableQuestions = function() {
  $('.survey-form .sortable').sortable({ handle: '.handle' });
  $('.survey-form .sortable').sortable({update(e, ui) {
    $.ajax({
      type: 'GET',
      url: $(this).data('url'),
      dataType: 'html',
      data: {
        questions: $(this).sortable('toArray').map((element) => {
        return element.toString().split('_')[1];
      })
    }
    });
  }});
};

const addQuestionToSurvey = () => $('.add-question').on('click', function(e) {
  e.preventDefault();
  $.ajax({
    type: 'POST',
    url: $(this).data('url'),
    dataType: 'script',
    data: { question_type_id: $(this).data('question_type_id')}
  })
});

const initializeDeleteQuestionFromSurvey = () => $('.delete-question').on('click', function(e) {
  e.preventDefault();

  $.ajax({
    type: 'DELETE',
    url: $(this).data('url'),
    dataType: 'script'
  })
});

const deleteQuestionFromSurvey = (element) => {

  var url = element.data('url');
  $.ajax({
    type: 'DELETE',
    url: url,
    dataType: 'script'
  })
}

const duplicateQuestion = () => $('.duplicate-question').on('click', function(e) {
  e.preventDefault();

  $.ajax({
    type: 'POST',
    url: $(this).data('url'),
    dataType: 'script'
  })
});

const addExistingQuestionToSurvey = () => $('.question-select').on('click', function(e) {
  e.preventDefault();
  $.ajax({
    type: 'POST',
    url: $(this).data('url'),
    dataType: 'script',
    data: { question_id: $(this).data('question_id'),
            survey_id: $(this).data('survey_id') }
  })
});

const addUnlockedQuestionToSurvey = () => $(document).on('click', '.unlock-question', function(e) {
  e.preventDefault();

  if (confirm('Are you sure you want to unlock and edit this question?')) {
    $.ajax({
      type: 'POST',
      url: $(this).data('url'),
      dataType: 'script',
      data: { question_id: $(this).data('question_id'),
              survey_id: $(this).data('survey_id') }
    })
  }
});

const preventSearch = () => $('.existing-question-search').on('keydown', function(e) {
  if (e.key === 'Enter') {
    e.preventDefault();
  }
});

const searchExistingQuestions = () => $('.existing-question-search').on('keyup', function(e) {
  var search = $('#question_search').val();

  setTimeout(function() {
    $.ajax({
      type: 'GET',
      url: $('#existing_question_form').attr("action"),
      dataType: 'script',
      data: { question_search: search },
      success: function() {
        addExistingQuestionToSurvey();
      }
    })
  }, 1500);
});

const resetExistingQuestions = () => $('.existing-question-search').on('search', function(e) {
  setTimeout(function() {
    $.ajax({
      type: 'GET',
      url: $('#existing_question_form').attr("action"),
      dataType: 'script'
    })
  }, 1500);
});

const autoSelectOnFocus = function() {
  $(document).on('focus', ".answer-option", function() { $(this).select(); } );
  $(document).on('focus', ".edit-question", function() { $(this).select(); } );
}

const toggleThumbsUpDown = () => $('.surveys .thumb-link').on('click', function(e) {
  e.preventDefault();

  let clickedIcon = $(this).find('.thumb-icon').first();
  clickedIcon.addClass("fas");
  clickedIcon.removeClass("far");
  if (clickedIcon.attr("class").includes('thumbs-up')) {
    const thumbsDownIcon = $('.thumbs-up-down-container').find('.fa-thumbs-down').first();
    thumbsDownIcon.removeClass("fas");
    thumbsDownIcon.addClass("far");
    thumbsDownIcon.attr('aria-label', 'Thumbs Down - Unselected');
    clickedIcon.attr('aria-label', 'Thumbs Up - Selected');
    $('#question_answers').val('Yes');
  } else {
    const thumbsUpIcon = $('.thumbs-up-down-container').find('.fa-thumbs-up').first();
    thumbsUpIcon.removeClass("fas");
    thumbsUpIcon.addClass("far");
    thumbsUpIcon.attr('aria-label', 'Thumbs Up - Unselected');
    clickedIcon.attr('aria-label', 'Thumbs Down - Selected');
    $('#question_answers').val('No');
  }
});

const starRating = () => $('.answers-container .star-rating').on('click', function(e) {
  e.preventDefault();
  var rating = $(this).attr('data');
  $('#question_answers').val(Number(rating));
  for (var i = 1; i <= Number(rating); i++){
    $('.answers-container .star-rating[data=' + i + ']').addClass('fa')
    $('.answers-container .star-rating[data=' + i + ']').attr('aria-label', 'star ' + i + ' selected')
    $('.answers-container .star-rating[data=' + i + ']').removeClass('far')
  }
  for (var i = (Number(rating) + 1); i <= 5; i++){
    $('.answers-container .star-rating[data=' + i + ']').addClass('far')
    $('.answers-container .star-rating[data=' + i + ']').attr('aria-label', 'star ' + i + ' unselected')
    $('.answers-container .star-rating[data=' + i + ']').removeClass('fa')
  }
});

$(document).on('turbolinks:load', function() {
  addQuestionToSurvey();
  initializeDeleteQuestionFromSurvey();
  duplicateQuestion();
  saveQuestionText();
  addQuestionAnswerOption();
  saveQuestionAnswer();
  initializeAnswerDelete();
  addExistingQuestionToSurvey();
  addUnlockedQuestionToSurvey();
  sortableQuestions();
  preventSearch();
  searchExistingQuestions();
  resetExistingQuestions();
  autoSelectOnFocus();
  toggleScale();
  toggleThumbsUpDown();
  starRating();
});

window.duplicateQuestion = duplicateQuestion;
window.updateQuestion = updateQuestion;
window.createQuestionAnswer = createQuestionAnswer;
window.updateAnswer = updateAnswer;
window.deleteAnswer = deleteAnswer;
window.deleteQuestionFromSurvey = deleteQuestionFromSurvey;

