const checkDates = () => $('#import-start-date, #import-end-date').on('change', function() {
  if($('#import-start-date').val() && $('#import-end-date').val()) {
    const url = $(this).data('url');
    const dates = [$('#import-start-date').val(), $('#import-end-date').val()];
    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'script',
      data: { dates }
    });
  }
});

const modifyModal = () => $('.modify-search-button').on('click', function() {
  checkDates();
})

$(document).on('turbolinks:load', function() {
  checkDates();
  modifyModal();
});

window.checkDates = checkDates;
