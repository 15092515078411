const initTagsChosen = (selectElement, selectLimit=null) => {
  $('.chosen-select').chosen({
    allow_single_deselect: true,
    no_results_text: 'No result found. Press enter to add ',
    hide_results_on_select: false,
    width: '100%',
    max_selected_options: selectLimit
  });

  return $('.chosen-search-input').keyup(function(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      const newValue = $(this).val();
      const numberOfSelections = selectElement.val().length;
      if (newValue && (!selectElement || numberOfSelections < selectLimit)) {
        const existingOption = selectElement.find("option").filter( function() { return $(this).html() === newValue;  }).length;
        if (!existingOption) {
          const option = $('<option>', { value: newValue, text: newValue }).prop("selected", true);
          selectElement.append(option);
          selectElement.trigger('chosen:updated').trigger('chosen:close');
        }
        e.target.focus();
      }
      return false;
    }
  });
}

const updateTags = (tagList, updateUrl) => {
  $.ajax({
    type: 'PUT',
    url: updateUrl,
    dataType: 'html',
    data: { field_type: { tag_list: tagList.chosen().val() } },
  });
};


const initDocumentTags = () => $('#editDocumentTags').on('shown.bs.modal', function() {
  initTagsChosen($('#dossiers_document_tag_list'));
});

$(document).on('turbolinks:load', () => initDocumentTags());

$(document).on('turbolinks:before-cache', () => $('.chosen-select').chosen('destroy'));

window.initTags = initTagsChosen;
window.updateTags = updateTags;
