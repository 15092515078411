const revealHeaderForm = () => $('#survey-event-header').on('click', function() {
  $('#survey-event-header').addClass('hidden');
  $('#survey-event-header-form').removeClass('hidden');
});

const hideHeaderForm = () => $('#survey-event-header').on('submit', function() {
  $('#survey-event-header').removeClass('hidden');
  $('#survey-event-header-form').addClass('hidden');
});

$(document).on('turbolinks:load', function() {
  revealHeaderForm();
  hideHeaderForm();
});
