const disableYearDropdownOnEdit = function() {
  $("[class^=edit-tab-attributes]").on('click', e => $('.academic-year').prop('disabled', true));
};

const removeFields = () => $(document).on('click', '.remove-infos', function(e) {
  const fieldParent = e.target.closest('.nested-infos');
  const deleteField = fieldParent ? fieldParent.querySelector('input[type="hidden"]') : null;
  if (deleteField) {
    deleteField.value = 1;
    fieldParent.style.display = 'none';
  }
  e.preventDefault();
});

const addFields = () => $(document).on('click', '.add-infos', function(e) {
  const time = new Date().getTime();
  const regexp = new RegExp($(this).data('id'), 'g');
  $(this).before($(this).data('fields').replace(regexp, time));
  e.preventDefault();
});

const selectTab = function() {
  if (location.hash) {
    $(location.hash).tab('show');
  }
};

document.addEventListener('turbolinks:load', () => disableYearDropdownOnEdit());

const ready = function() {
  removeFields();
  addFields();
};

$(document).ready(ready);
$(document).on('page:change', ready);
$(document).on('turbolinks:load', () => selectTab());
