const toggleButton = function(e) {
  e.preventDefault();

  // Visual Changes
  $(this).find('i').toggleClass('fa-toggle-on fa-toggle-off');
  $(this).find('i').toggleClass('btn-outline-danger btn-outline-primary');
  $(this).next().toggleClass('d-none'); // Hide/Show Unsaved Toggle Message

  // Update value for form
  const target = $('#' + $(this).data('target'));
  const new_value = !(target.val() === "true");
  return target.val(new_value);
};

const showTermDropdowns = () => $('.add-term').on('click', function(e) {
  e.preventDefault();

  $.ajax({
    type: 'GET',
    url: '/terms/new',
    dataType: 'script',
    data: {}
  });
});

const createTerm = () => $('.term-name-form').on('submit', function() {
  const academicYear = $('#term_academic_year_id');
  const termName = $('#term_name');
  $.ajax({
    type: 'POST',
    url: '/terms',
    dataType: 'script',
    data: { academic_year_id: academicYear, name: termName }
  });
});

const initializeUpdateTerm = () => $('.datepicker').on('change', function(e) {
  e.preventDefault();

  updateTerm($(this));
});

const initializeDisableTerms = () => $('.datepicker').each(function(index, datepicker) {
  if($('.term-dropdowns').length == 0) {return}
  if ($(this).prop('id') == 'term_start_date') {
    var disable = getDisabledStartDates($(this));
  } else {
    var disable = getDisabledEndDates($(this));
  }
  if (disable != null) { disable = disable.split(' ') };
  updateDatepicker($(this), disable);
});

const getDisabledStartDates = (datepicker) => {
  return datepicker.closest('.term-date-form').find('#term_start_dates_disabled').val();
};

const getDisabledEndDates = (datepicker) => {
  return datepicker.closest('.term-date-form').find('#term_end_dates_disabled').val();
};

const updateDatepicker = (datepicker, dates) => {
  datepicker.datepicker('destroy').datepicker({
    format: "yyyy/mm/dd",
    changeMonth: true,
    changeYear: true,
    showOtherMonths: true,
    selectOtherMonths: true,
    datesDisabled: dates,
    forceParse: false
  });
};

const updateTerm = (element) => {
  if($('.term-dropdowns').length == 0) {return}
  const form = element.closest('.term-date-form');
  const startDate = form.find('#term_start_date').val();
  const endDate = form.find('#term_end_date').val();
  $.ajax({
    type: 'PUT',
    url: form[0].action,
    dataType: 'script',
    data: { start_date: startDate, end_date: endDate }
  });
}

$(document).on('turbolinks:load', function() {
  showTermDropdowns();
  initializeUpdateTerm();
  initializeDisableTerms();
  $('.admin__configurations .toggle-config').on('click', toggleButton);
  $('.tams-chosen').chosen({ width: '100%' })

  return $('#fileInput').on('change', function() {
    const fileName = $(this).val();
    return $('.custom-file-label').html(fileName);
  });
});

window.createTerm = createTerm;
window.updateTerm = updateTerm;
window.initializeDisableTerms = initializeDisableTerms;
window.getDisabledStartDates = getDisabledStartDates;
window.getDisabledEndDates = getDisabledEndDates;
