const toggleNav = function(targetElement) {
  $('#side_nav').toggleClass('sidenav-open sidenav-closed');
  targetElement.attr('aria-expanded', function(i, attr) {
    if (attr === 'true') { return 'false'; } else { return 'true'; }
  });
};

const closeSideNav = function() {
  $('#side_nav').removeClass('sidenav-open');
  $('#side_nav').addClass('sidenav-closed');
};

const changeButtonText = function(targetElement) {
  let text;
  const buttonTextContainer = $('#buttonText');
  if ($(buttonTextContainer).hasClass('has-submenu')) {
    $(buttonTextContainer).removeClass('has-submenu');
    text = $('#hide_module_selector_text').text();
    $(buttonTextContainer).text(text);
  } else {
    $(buttonTextContainer).addClass('has-submenu');
    text = $('#show_module_selector_text').text();
    $(buttonTextContainer).text(text);
  }
};

const hideFlashMessage = function() {
  $('#flash').hide('slow');
};

const hidePopovers = () => $('.popover').popover('hide');

$(document).on('turbolinks:load', function() {
  changeButtonText();
  $('#side_nav_toggle').click(function(e) {
    e.stopPropagation();
    hideFlashMessage();
    toggleNav($(this));
    hidePopovers();
    changeButtonText();
  });

  $('#side_nav').click(function(e) {
    e.stopPropagation();
  });

  $('body,html').click(function(e) {
    if ($('#side_nav').hasClass('sidenav-open')) {
      e.stopPropagation();
      closeSideNav();
      changeButtonText();
    }
  });

  $(document).keydown(function(e) {
    const escape_keycode = 27;
    if (e.which === escape_keycode) {
      e.stopPropagation();
      $('#navbarNavDropdown').collapse('hide');
      closeSideNav();
      changeButtonText();
    }
  });

  // Sync the module side bar if it's fake
  return $('#side_nav input[name=fake_module_sidebar]').each(function(e) {
    if ($(this).val() !== "true") { return; }

    $.ajax({
      type: 'GET',
      url: $(this).data('path'),
      dataType: 'html',
      success(html) {
        $('#side_nav').replaceWith(html);
      }
    });
  });
});
