const initCaseTypeChosen = function() {
  $('#kase_template_case_type').chosen({width: '100%'});
  $('#kase_template_case_type_chosen .chosen-search-input').attr('aria-label', 'case type');
};

const initOrgsChosen = function() {
  $('#orgs').chosen({hide_results_on_select: true, width: '100%'});
  $('#orgs_chosen .chosen-search-input').attr('aria-label', 'organizations');
};

const initEmailRemindersOverrideChosen = function() {
  $('#kase_template_email_reminders').chosen({width: '100%'});
  $('#kase_template_email_reminders_chosen .chosen-search-input').attr('aria-label', 'email reminders');
};

const settingsEnableForm = function() {
  $('.edit-settings-btn').on('click', function(e) {
    e.preventDefault();
    $(this).hide();
    $('label span').show();
    $('#settings-edit-form-buttons').show();
    document.getElementById('settings-field').disabled = false;
    $('#kase_template_case_type, #orgs, #kase_template_email_reminders, #kase_template_final_reminders').trigger("chosen:updated");
  });
  $('.settings-form-cancel').on('click', function(e) {
    e.preventDefault();
    $('.edit-settings-btn').show();
    $('label span').hide();
    $('#settings-edit-form-buttons').hide();
    document.getElementById('settings-field').disabled = true;
    $('#kase_template_case_type, #orgs, #kase_template_email_reminders, #kase_template_final_reminders').trigger("chosen:updated");
  });
};

const extEvaluationEnableFields = function() {
  $('.edit-ext-evaluation-btn').on('click', function(e) {
    e.preventDefault();
    $(this).hide();
    document.getElementsByClassName('btn-group')[0].style.display = 'block';
    document.getElementsByClassName('btn-group-2')[0].style.display = 'block';
    $('.form-group label span').show();
    $('#ext-eval-form-buttons').show();
    $('.hidden-instructions-form').each(function() { this.style.display = 'block'; });
    $('.trix-content').each(function() { this.style.display = 'none'; });
    const elements = document.getElementsByClassName('ext-eval-fields');
    return (() => {
      const result = [];
      for (let i = 0, end = elements.length-1; i <= end; i++) {
        result.push(elements[i].disabled = false);
      }
      result;
    })();
  });
  $('.ext-evaluation-form-cancel').on('click', function(e) {
    e.preventDefault();
    $('.edit-ext-evaluation-btn').show();
    $('.btn-group').hide();
    $('.btn-group-2').hide();
    $('.form-group label span').hide();
    $('#ext-eval-form-buttons').hide();
    $('.hidden-instructions-form').each(function() { this.style.display = 'none'; });
    $('.trix-content').each(function() { this.style.display = 'block'; });
    const elements = document.getElementsByClassName('ext-eval-fields');
    return (() => {
      const result = [];
      for (let i = 0, end = elements.length-1; i <= end; i++) {
        result.push(elements[i].disabled = true);
      }
      result;
    })();
  });
};

const updateLocationHash = function() {
  if (location.hash !== '') {
    $('a[href="'+location.hash+'"]').tab('show');
  }

  $('a[data-toggle="pill"]').on('shown.bs.tab', function(e) {
    location.hash = $(e.target).attr('href').substr(1);
    if (location.hash === '#v-pills-summary') {
      location.reload();
    }
  });
};

const hideSettingsOnClone = function() {
  $('#kase_template_template_id').on('change', function() {
    if ($(this).val()) {
      $('#kase_template_type').addClass('hidden');
      $('#kase_template_orgs').addClass('hidden');
      $('#kase_template_email_reminders').addClass('hidden');
      $('#kase_template_final_reminders').addClass('hidden');
    } else {
      $('#kase_template_type').removeClass('hidden');
      $('#kase_template_orgs').removeClass('hidden');
      $('#kase_template_email_reminders').removeClass('hidden');
      $('#kase_template_final_reminders').removeClass('hidden');
    }
  });
};

document.addEventListener('turbolinks:load', function() {
  initCaseTypeChosen();
  initOrgsChosen();
  initEmailRemindersOverrideChosen();
  settingsEnableForm();
  extEvaluationEnableFields();
  updateLocationHash();
  hideSettingsOnClone();
});

$(document).on('turbolinks:before-cache', () => $('#kase_template_case_type, #orgs, #kase_template_email_reminders').chosen('destroy'));
