const initCommitteeChosen = function() {
  $('#committee_chair_id, #committee_member_id').chosen({width: '100%'});
  $('#committee_chair_id_chosen .chosen-search-input, #committee_member_id_chosen .chosen-search-input').attr('aria-label', 'Select one or more ... ');
};

const initOrganizationChosen = function() {
  $('#committees-org-select').chosen({width: '100%'});
  $('#committees_org_select_chosen .chosen-search-input').attr('aria-label', 'Select an Organization');
}

$(document).on('turbolinks:load', function() {
  initCommitteeChosen();
  initOrganizationChosen();
});

$(document).on('turbolinks:before-cache', () => $('#committee_chair_id, #committee_member_id, #committee_organization_id').chosen('destroy'));
