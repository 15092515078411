const folderSelect = () => $('#upload-dossier').on('shown.bs.modal', function() {
  if (location.pathname === '/') {
    $('#dossiers_document_parent_folder').chosen({width: '75%'});
    $('#dossiers_document_parent_folder').attr('aria-label', 'Select Folder');
    $('#dossiers_document_document_type_id').chosen({width: '75%'});
    $('#dossiers_document_document_type_id').attr('aria-label', 'Select Document Type');
    $('#dossiers_document_parent_folder').val('').trigger('chosen:updated');
    $('#dossiers_document_document_type_id').val('').trigger('chosen:updated');
    $('#upload-doc-form')[0].reset();
    $('.custom-file-label').html('');
  }
});

const cvTemplateSelect = () => $('#download_cv').on('shown.bs.modal', function() {
  $('#activity_template_id').chosen({width: '100%', inherit_select_classes: true});
  $('#activity_template_id').attr('aria-label', 'Select CV Template');
  $('#activity_template_id').val('').trigger('chosen:updated');
  $('#cv_submit_btn').attr('disabled', true);
});

const requireCvTemplateSelection = () => $('#activity_template_id').on('change', () => $('#cv_submit_btn').attr('disabled', !!!$('#activity_template_id').val()));

const activityFormSelect = () => $('#add_activity').on('show.bs.modal', function() {
  $('#activity_activity_form_id').chosen({width: '100%', inherit_select_classes: true});
  $('#activity_activity_form_id_chosen .chosen-search-input').attr('aria-label', 'Select Activity');
  $('#activity_activity_form_id').attr('aria-label', 'Select Activity Form');
  $('#activity_activity_form_id').val('').trigger('chosen:updated');
});

const farActivityFormSelect = () => $('#far_add_activity').on('show.bs.modal', function() {
  $('#activity_activity_form_id').chosen({width: '100%', inherit_select_classes: true});
  $('#activity_activity_form_id_chosen .chosen-search-input').attr('aria-label', 'Select Activity');
  $('#activity_activity_form_id').attr('aria-label', 'Select Activity Form');
  $('#activity_activity_form_id').val('').trigger('chosen:updated');
});

const redirectOnActivityFormSubmit = () => $('#add-activity-form').on('submit', function() {
  const activity_form_id = $('#activity_activity_form_id').val();
  if (activity_form_id) {
    const params = '?home_request=true&form_id=' + activity_form_id
    window.location = $('#activity_activity_form_id').data('url') + params
  }
  return false
});

const farRedirectOnActivityFormSubmit = () => $('#far-add-activity-form').on('submit', function() {
  const activity_form_id = $('#activity_activity_form_id').val();
  const url = $('#activity_activity_form_id').data('url')
  if (activity_form_id) {
    const params = '?far_request=true&form_id=' + activity_form_id
    window.location = url + params
  }
  return false
});

const cvDownloadDismissModal = () => $('#hot-button-cv-download').on('submit', () => $('#download_cv').modal('hide'));

const enableActivityFormSubmit = () => $('#activity_activity_form_id').on('change', function() {
  const activity_form_id = $(this).val();
  if (activity_form_id) {
    $('#activity_submit_btn').attr('disabled', false)
  }
});


const cvFormatRouteSelector = () => $('.cv-format-radio').on('change', function() {
  const url = $(this).data('url');
  $(this).closest('form').attr('action', url);
});

const clearErrorMessage = () => $('#add_activity').on('shown.bs.modal', () => $('#add_activity .flash-container').empty());

document.addEventListener('turbolinks:load', function() {
  folderSelect();
  cvTemplateSelect();
  cvDownloadDismissModal();
  activityFormSelect();
  redirectOnActivityFormSubmit();
  enableActivityFormSubmit();
  cvFormatRouteSelector();
  requireCvTemplateSelection();
  clearErrorMessage();
  farActivityFormSelect();
  farRedirectOnActivityFormSubmit();

  if ($('.activity-success-message').length > 0){
    $('.success-modal').modal({show: true, backdrop: 'static'});
    window.history.replaceState({}, document.title, '/');
  }
});
