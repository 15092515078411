const enabledSubmitOnSelection = () => $('#export_type').on('change', function() {
  const $exportBtn = $('#export-type-submit');
  const $exportText = $('#export-type-help-text');

  if ($(this).val()) {
    $exportBtn.removeClass('disabled');
    $exportBtn.attr('disabled', false);
    $exportText.addClass('hidden');
  } else {
    $exportBtn.addClass('disabled');
    $exportBtn.attr('disabled', true);
    $exportText.removeClass('hidden');
  }
});

const exportSubmit = () => $('#export_form').on('submit', function(e) {
  if ($('#export_type').val()) {
    $('#export-wait').modal({ show: true, backdrop: 'static' });
    invalidateDataExporterCookie();
   watchForCookie();
  } else {
    return false;
  }
});

const isDownloadComplete = function() {
  if (document.cookie) {
    return (document.cookie.includes('data_exporter_download_finished=true'));
  }
};

var watchForCookie = function() {
  let watchingCookie;
  watchingCookie = setInterval((function() {
    if (isDownloadComplete()) {
      clearInterval(watchingCookie);
      $('#export_type').val(null);
      $('#export-type-submit').addClass('disabled');
      $('#export-type-help-text').removeClass('hidden');
      $('#export-wait').modal('hide');
    }
  }), 100);
};

var invalidateDataExporterCookie = function() {
  const cookie_name = $('#export_form').data('cookie');
  document.cookie = cookie_name + '= ; expires= ' +  new Date('01/01/01');
};

$(document).on('turbolinks:load', function() {
  enabledSubmitOnSelection();
  exportSubmit();
});
