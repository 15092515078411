const activitySelectForm = function($that){
  resetMappings();
  if ($that.val()) {
    $.ajax({
      type: 'GET',
      url: $that.data('url'),
      data: { activity_form_id: $that.val() },
      dataType: 'script'
    });
  }
};

const activityFormsFieldTypeSelect = function($that){
  if ($that.val()) {
    $.ajax({
      type: 'GET',
      url: $that.data('url'),
      dataType: 'script',
      data: {
        activity_form_id: $('#activity_forms').val(),
        activity_forms_field_type_id: $that.val()
      }
    });
  } else {
    $('#wizard_import_headers').html('');
    $('#save_header_mappings').addClass('hidden');
  }
};

const watchForMappingSelectsChanges = () => $(document).on('wizard_mapping_select:change', function() {
  const $this = $(event.target);

  if ($this.is('#activity_forms')) {
    activitySelectForm($this);
  } else if ($this.is('#activity_forms_field_types')) {
    activityFormsFieldTypeSelect($this);
  } else if ($this.is('#headers')) {
    headerSelect($this);
  }
});

var headerSelect = function($that){
  if ($that.data('boolean') || $that.data('select')) {
    $.ajax({
      type: 'GET',
      url: $that.data('importOptionsUrl'),
      dataType: 'script',
      data: {
        column_name: $that.val(),
        activity_forms_field_type_id: $('#activity_forms_field_types select').val()
      }
    });
  } else {
    $('#save_header_mappings').toggleClass('hidden', !$that.val());
  }
};

const saveMapping = () => $('#save_mapping').on('click', function(e) {
  e.preventDefault();
  $.ajax({
    type: 'POST',
    url: $(this).attr('href'),
    dataType: 'script',
    data: {
      activity_form_id: $('#activity_forms').val(),
      csv_header: $('#wizard_import_headers select').val(),
      activity_forms_field_type_id: $('#activity_forms_field_types select').val()
    }
  });
});

var resetMappings = function() {
  $('#activity_forms_field_types').html('');
  $('#wizard_import_headers').html('');
  $('#save_header_mappings').addClass('hidden');
};

const watchForModalMappingSelectsChanges = () => $(document).on('wizard_mapping_modal_select:change', function() {
  if (allFieldsMapped()) {
    $('#save_mappings_container').removeClass('hidden');
  } else {
    $('#save_mappings_container').addClass('hidden');
  }
});

var allFieldsMapped = function($that) {
  const selectCount = $('.modal-body select:visible').length;
  let selectsWithVal = 0;
  $('.modal-body select:visible').each(function(i) {
    if ($(this).val()) {
      selectsWithVal++;
    }
  });
  return selectCount === selectsWithVal;
};

let saveCount = 0;
const saveModalMappings = () => $(document).on('wizard_mapping_modal_select:save', function(e, url) {
  saveCount++;
  if (saveCount <= 1) {
      $.ajax({
      type: 'POST',
      url,
      dataType: 'script',
      data: {
        mapped_values: mappedValsFromModal($(this)),
        activity_form_id: $('#activity_forms').val(),
        activity_form_column: $('#activity_form_identifier').val(),
        column_name: $('#wizard_import_headers select').val(),
        activity_forms_field_type_id: $('#activity_forms_field_types select').val()
      },
      success(html) {
        saveCount = 0;
      }
    });
  }
});

var mappedValsFromModal = function($that) {
  const mappedVals = {};
  $('.modal-body select').each(function(i) {
    mappedVals[$(this).attr('id')] = $(this).val();
  });
  return mappedVals;
};

const mapMultipleActivityForms = () => $('#activity_form_identifier').on('change', function() {
  $.ajax({
    type: 'GET',
    url: $(this).data('url'),
    dataType: 'script',
    data: {
      column_name: $(this).val()
    }
  });
});

$(document).on('turbolinks:load', function() {
  watchForMappingSelectsChanges();
  watchForModalMappingSelectsChanges();
  saveMapping();
  saveModalMappings();
  activitySelectForm($('#activity_forms'));
  mapMultipleActivityForms();
});
