const sortableSections = function() {
  $('.activity_templates__structures .sortable').sortable({update(e, ui) {
    $.ajax({
      type: 'GET',
      url: $(this).data('url'),
      dataType: 'html',
      data: $(this).sortable('serialize')
    });
  }
  });
};

const hideCvTooltips = function() {
  $('.tooltip').tooltip('hide');
};

const workloadPercentModal = () => $('.sortable').on('click', '.workload-percent', function(e) {
  e.preventDefault();
  const url = $(this).data('url');
  const id = $(this).data('id');
  const title = $(this).data('title');
  $.ajax({
    type: 'GET',
    url: url,
    dataType: 'html',
    data: { activity_template_id: id },
    success(html) {
      $('#editActivitySection .modal-body').html(html);
      $('#editActivitySection .modal-title').html(title);
      $('#editActivitySection').modal({show: true, backdrop: 'static'});
      validateWorkload();
    }
  });
});

const validateWorkload = () => $('#section-workload').on('submit', function(e) {
  e.preventDefault();
  const category_input = $('#section-workload #category_id').val();
  const url = $(this).attr('action');
  $.ajax({
    url: url,
    method: 'POST',
    dataType: 'html',
    data: { category_input: category_input },
    success(msg) {
      if (msg === 'no_nonconforming_forms') {
        location.reload();
      } else if (confirm(msg)) {
        updateConfirmedWorkload(url, category_input);
        $('#editActivitySection').modal('hide');
      };
    }
  });
});

const updateConfirmedWorkload = (url, category_input) => {
  $.ajax({
    url: url,
    method: 'PUT',
    dataType: 'html',
    data: { category_input: category_input },
    success(){
      location.reload();
    }
  });
};

const editActivitySection = () => $('.sortable').on('click', '.edit-activity-section', function() {
  const url = $(this).data('url');
  const id = $(this).data('id');
  const title = $(this).data('title');
  $.ajax({
    type: 'GET',
    url,
    dataType: 'html',
    data: { activity_template_id: id },
    success(html) {
      $('#editActivitySection .modal-body').html(html);
      $('#editActivitySection .modal-title').html(title);
      $('#editActivitySection').modal({show: true, backdrop: 'static'});
    }
  });
});

const deleteActivitySection = () => $('.sortable').on('click', '.delete-activity-section', function(e) {
  e.stopImmediatePropagation();
  if (confirm('Are you certain you wish to delete this section?')) {
    const url = $(this).data('url');
    const id = $(this).data('id');
    const section_id = $(this).data('sectionId');
    $.ajax({
      type: 'DELETE',
      url,
      dataType: 'html',
      data: { activity_template_id: id },
      success(html) {
        $('#activity_section_' + section_id).remove();
        hideCvTooltips();
        if ($('#sortable_sections li').length === 0) {
          $('#no-activity-sections').attr('hidden', false);
        }
      }
    });
  }
});

const addSummary = () => $(document).on('click', '.add-summary', function(e) {
  const source_id   = $(this).data('sourceId');
  const source_type = $(this).data('sourceType');
  const url         = $(this).data('sectionUrl');
  const options_url = $(this).data('summaryOptionsUrl');
  const modalTitle = 'Add Summary';
  $('.summary-target-options').html('');
  $('.group-target-options').html('');
  $('.summary-type-select').val('');
  $('#create_summary_form').attr('action', url);
  $('#createAndEditSummary .modal-title').html(modalTitle);
  $('#createAndEditSummary').modal({show: true, backdrop: 'static'});
  $('#source_id').val(source_id);
  $('#source_type').val(source_type);
  $('.summary-type-select').data('url', options_url);
  $('.summary-target-select').data('url', options_url);
  e.preventDefault();
});

const addSubsection = () => $('.sortable').on('click', '.add-subsection', function(e) {
  const id    = $(this).data('sectionId');
  const label = $(this).data('sectionLabel');
  const url   = $(this).data('sectionUrl');
  $('#create_subsection_form').attr('action', url);
  $('#subsection-parent-label').html(label);
  $('#createSubsection').modal({show: true, backdrop: 'static'});
  $('.modal #activity_section_name').val('');
  $('#activity_section_parent_id').val(id);
  e.preventDefault();
});

const mapActivities = () => $('.sortable').on('click', '.map-activities', function(e) {
  e.preventDefault();
  const id    = $(this).data('sectionId');
  const label = $(this).data('sectionLabel');
  const url   = $(this).data('sectionUrl');
  const cat_id   = $(this).data('categoryId');

  $('#map-activity-form').attr('action', url);
  $('#mapActivityForms').modal({show: true, backdrop: 'static'});

  if (cat_id !== undefined) {
    $('#category-mapping-select').val(cat_id);
    $('#category-mapping-select').attr('disabled', true);
    $('#category-mapping-select').trigger('change');
  };
});

const resetActivityForms = (category_id, activity_template_id, url) => $.ajax({
  type: 'GET',
  url,
  dataType: 'html',
  data: { id: category_id, activity_template_id },
  success(html) {
    html = JSON.parse(html);
    $('#activity_forms').empty().append(html.activity_forms);
    $('#subcategory-mapping-select').html(html.subcategory_options);
    subcategoryMappingSelect();
  }
});

const categoryMappingSelect = function() {
  $('#category-mapping-select').on('change', function() {
    const cat_id = $(this).val();
    const activity_template_id = $('#activity_sections_activity_form_activity_template_id').val();
    const url = $(this).data('url');
    resetActivityForms(cat_id, activity_template_id, url);
  });
};

var subcategoryMappingSelect = function() {
  $('#subcategories_mapping_select').on('change', function() {
    const sub_cat_id = $(this).val();
    const cat_id = $(this).data('categoryId');
    const activity_template_id = $('#activity_sections_activity_form_activity_template_id').val();
    if (sub_cat_id !== '') {
      $.ajax({
        type: 'GET',
        url: $(this).data('url'),
        dataType: 'html',
        data: {
          subcategory_id: sub_cat_id,
          category_id: cat_id,
          activity_template_id
        },
        success(html) {
          $('#activity_forms').empty().append(html);
        }
      });
    } else {
      const url = $(this).data('catUrl');
      resetActivityForms(cat_id, activity_template_id, url);
    }
  });
};

const mappingChevronToggle = () => $('#sortable_sections').on('click', function() {
  const $this = $(event.target);
  if ($this.hasClass('mapping-chevron')) {
    $this.toggleClass('fa-chevron-right');
    $this.toggleClass('fa-chevron-down');
  }
});

const updateStyleAndFormat = function() {
  $('.cv-body').css('font-family', $('#cv_template_font_style_id option:selected').text());
  $('.document-section').css('font-size', $('#section_font_size').val() + "px");
  $('#cv_template_section_font_size').val($('#section_font_size').val());
  $('.document-subsection').css('font-size', $('#subsection_font_size').val() + "px");
  $('#cv_template_subsection_font_size').val($('#subsection_font_size').val());
  $('.document-activity').css('font-size', $('#cv_template_activity_font_size').val() + "px");
  $('.document-activity').css('line-height', $('#cv_template_activity_line_height').val());
};

const loadStyles = () => $('#CvTemplate .btn.style').on('click', function(event) {
  event.preventDefault();
  const $button = $(this);
  const header = $(this).data('header');
  const style = $(this).data('style');
  if ($('#cv_template_' + header + '_' + style).val() === 'true') {
    $('.document-' + header).removeClass(style);
    $('#cv_template_' + header + '_' + style).val('false');
    $button.removeClass('active-option');
  } else {
    $('.document-' + header).addClass(style);
    $('#cv_template_' + header + '_' + style).val('true');
    $button.addClass('active-option');
  }
});

const setupStyleAndFormat = function() {
  $('.style-and-format :input').on('input', () => updateStyleAndFormat());
  updateStyleAndFormat();
};

const updateReviewAndPublishDocument = function() {
  if ($('.review-and-publish').length === 0) { return; }
  $('.document').css('font-family', $('.document').data('font-family'));
};

const mapActivityFormValidation = () => $('#map-activity-form').on('submit', function(e) {
  if ($('#activity_forms option:selected').val() === undefined) {
    $('#activity-form-mapping-errors').attr('hidden', false);
    return false;
  }
});

const activityDateOptions = function() {
  if ($('#cv_template_activity_date_option').val() === 'Date Range') {
    $('#activity_date_range_options').attr('hidden', false);
  }
  $('#cv_template_activity_date_option').on('change', function() {
    if ($(this).val() !== 'Date Range') {
      $('#activity_date_range_options').attr('hidden', true);
    } else {
      $('#activity_date_range_options').attr('hidden', false);
    }
  });
};

const toggleOrganizationalUnits = () => $('#cv_template_personal').on('change', e => $('#organizations_section').toggle('hidden'));

const progressIndicator = () => $('.cv-step-btn').click(function(e) {
  e.preventDefault();
  const step = $(this).data('step');
  const no_submit_form = $('.activity-template-no-form-submit:first');
  if (no_submit_form.length > 0) {
    const href = $('.activity-template-no-form-submit:first').attr('href');
    $('.activity-template-no-form-submit:first').attr('href', href + step);
    $('.activity-template-no-form-submit:first')[0].click();
  } else {
    $('.activity-template-form #activity_template_next_action').val(step);
    $('.activity-template-form').submit();
  }
});

const initOrgsChosen = function() {
  $('#cv_template_organizations').chosen({ width: '100%', inherit_select_classes: true });
  $('#cv_template_organizations_chosen').attr('aria-label', 'Select Organizations');
};

$(document).on('turbolinks:load', function() {
  sortableSections();
  editActivitySection();
  deleteActivitySection();
  addSubsection();
  mapActivities();
  addSummary();
  mapActivityFormValidation();
  categoryMappingSelect();
  mappingChevronToggle();
  setupStyleAndFormat();
  updateReviewAndPublishDocument();
  activityDateOptions();
  loadStyles();
  toggleOrganizationalUnits();
  progressIndicator();
  workloadPercentModal();
  initOrgsChosen();
});

$(document).on('turbolinks:before-cache', () => $('#cv_template_organizations').chosen('destroy'));
