const filterSurveyEventReportResults = () => $('#survey_event_reports_filters').on('change', function(e) {
  filterResults(e);
});

const clearfilters = () => $('.clear-filters').on('click', function(e){
  $('#ser-org-select').val('').trigger('chosen:updated');
  $('#ser-term-select').val('').trigger('chosen:updated');
  filterResults(e);
});

const filterResults = function(e) {
  e.preventDefault();
  const orgId    = $('#ser-org-select').val();
  const termId   = $('#ser-term-select').val();
  $.ajax({
    type: 'GET',
    url: $('#survey_event_reports_filters').attr('action'),
    dataType: 'script',
    data: { organization_id: orgId, term_id: termId },
  });
};

const initChosen = function() {
  $('#ser-org-select').chosen({ width: '100%'});
  $('#ser-org-select').attr('aria-label', 'Filter by Academic Unit');
  $('#ser-term-select').chosen({ width: '100%'});
  $('#ser-term-select').attr('aria-label', 'Filter by Term');
};

$(document).on('turbolinks:load', function() {
  filterSurveyEventReportResults();
  clearfilters();
  initChosen();
});

$(document).on('turbolinks:before-cache', function() {
  $('#ser-org-select').chosen('destroy');
  $('#ser-term-select').chosen('destroy');
});
