const initChosen = function() {
  $('.accreditation-orgs').chosen({
   placeholder_text_multiple: $('.accreditation-orgs').data('prompt'),
   width: '100%'
  });

  $('#aacsb-org-select').chosen({width: '100%'});
  $('#abets-appen-org-select').chosen({width: '100%'})
  $('.abets-org-select').chosen({width: '100%'});

  $('#aacsb_org_select_chosen .chosen-search-input').attr('aria-label', 'aacsb orgs');
  $('#abets_org_select_chosen .chosen-search-input').attr('aria-label', 'abet orgs');
  $('#abets_appen_org_select_chosen .chosen-search-input').attr('aria-label', 'abet orgs');
};

const updateBulkDownloadFormPath = () => $('#bulk_download_form #activity_template_id').on('change', function() {
  let id = $(this).val();
  if (id === '') {
    id = $('#activity_template_id').data('backupValue');
  }

  return $(this).closest('form').attr('action', '/activity_templates/' + id + '/bulk_download');
});

const selectAllUsers = () => $('.accreditation-users-list .select-all').on('click', function() {
  const formName = $(this).data('target');
  if ($('#check_box_' + formName).prop('checked')) {
    return $('#' + formName + ' :checkbox').prop('checked', true);
  } else {
    return $('#' + formName + ' :checkbox').prop('checked', false);
  }
});

document.addEventListener('turbolinks:load', function() {
  initChosen();
  updateBulkDownloadFormPath();
  selectAllUsers();
});

$(document).on('turbolinks:before-cache', () => $('.accreditation-orgs').chosen('destroy'));
