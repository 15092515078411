const initStepsChosen = () => $('#step_modal').on('show.bs.modal', function() {
  $('select').chosen({ width: '100%' });
  $('.chosen-search-input').attr('aria-label', 'assignee');
  return setDropDowns();
});

var setDropDowns = function() {
  const isUser = $('#step_type input:radio:checked').val() === 'user';
  const isCandidate = $('#step_type input:radio:checked').val() === 'candidate';
  if (!isCandidate) {
    $('#user_select').toggle(!!isUser);
    $('#committee_select').toggle(!isUser);
    $('#packet_section_select').toggle(false);
    return $('#feedback_type').toggle(true);
  } else {
    $('#user_select').toggle(false);
    $('#committee_select').toggle(false);
    $('#packet_section_select').toggle(isCandidate);
    return $('#feedback_type').toggle(false);
  }
};

$(document).on('turbolinks:load', () => initStepsChosen());

$(document).on('turbolinks:before-cache', () => $('.chosen-select').chosen('destroy'));

$(document).on('change', '#step_type input:radio', function() {
  $('select').val('').trigger('chosen:updated');
  return setDropDowns();
});
