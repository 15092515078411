$(document).on('turbolinks:load', function() {
  $('.submit_cr_form').on('click', function(e) {
    const id = $(this).data('id');
    const formId = $(this).data('formId');
    if ($.trim($('#response_input_' + id).val())) {
      $('#candidate_response_confirm').modal({show: true, backdrop: 'static'});
      $('#submit_response').attr('data-form-id', formId);
    } else {
      $('#response_input_' + id).addClass('form-error');
      $('#response_error_' + id).removeClass('hidden');
    }
  });

  $('#submit_response').on('click', function() {
    const formIdToSubmit = $(this).data('formId');
    $(formIdToSubmit).submit();
  });

  $('#continue_editing').on('click', () => $('#candidate_response_confirm').modal('hide'));
});

$(document).on('turbolinks:before-cache', function() {
  $('#candidate_response_documents').chosen('destroy');
  $('#candidate_response_due_date').datepicker('destroy');
});
